import BrokerUserSessionsController from "../Controllers/BrokerServices/BrokerUserSessions"
import BrokerUserSessionsHistoryController from "../Controllers/BrokerServices/BrokerUserSessionsHistory"
import OrdersController from "../Controllers/BrokerServices/Orders"

class BrokerServicesController {
    constructor(){
        this.brokerUserSessionsHistoryController = new BrokerUserSessionsHistoryController();
        this.brokerUserSessionsController = new BrokerUserSessionsController();
        this.ordersController = new OrdersController();
    }

    init() {
        this.brokerUserSessionsHistoryController.init();
        this.brokerUserSessionsController.init();
        this.ordersController.init();

        try {
            this.tabstrip = $("#brokerServicesTabStrip").kendoTabStrip().data("kendoTabStrip");
            this.tabstrip.select(0);

            this.resize();

        } catch (error) {

            tradeideas.errorHandler(error);
        }

        $(window).resize(function () {

            this.resize();

        }.bind(this));

    }

    resize() {
 
      if(this.ordersController)
        this.ordersController.resize();

      if(this.brokerUserSessionsController)
        this.brokerUserSessionsController.resize();

      if(this.brokerUserSessionsHistoryController)
        this.brokerUserSessionsHistoryController.resize();
    }

}

export {BrokerServicesController as default};