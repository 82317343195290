﻿import { tradeideas } from "../Server/tradeideas"

class SettingsController {
    init() {
        
        try {

          
        } catch (error) {

            tradeideas.errorHandler(error);
        }

    }

    getSettings() {

        var settings = JSON.parse(localStorage.getItem("ttapiTesterSettings"));
        return settings || {};
    }

    saveSettings(settings) {
        if (settings)
            localStorage.setItem("ttapiTesterSettings", JSON.stringify(settings));
    }

}

export const settingsController = new SettingsController();