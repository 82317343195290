﻿import { tradeideas } from "../../Server/tradeideas"

class QuotesController {


    constructor(){
        this.latestData = {}

    }
    init() {

        try {

            this.dsQuotes = new kendo.data.DataSource(
                {
                    schema: {
                        model: {
                            id: "Symbol",
                            fields: {
                                Symbol: { editable: false },
                                Time: { nullable: true, type: "date" },
                                Bid: { nullable: true, type: "number" },
                                BidSize: { nullable: true },
                                BidExchange: { nullable: true },
                                Ask: { nullable: true, type: "number" },
                                AskSize: { nullable: true, type: "number" },
                                AskExchange: { nullable: true },
                                MessageCount: { nullable: true, type: "number" }
                            }
                        }
                    },
                    batch: false,
                    change: function (e) {
                    }
                });

            $("#quoteGrid").on("keydown", function (event) {

                if (event.key == "Delete") {

        
                    //dataItem = this.objQuoteGrid.dataItem(this.objQuoteGrid.select());

                    tradeideas.unsubscribeFromQuote(this.lastDataItem.Symbol);

                    setTimeout(function (e) {
                        this.objQuoteGrid.dataSource.remove(this.lastDataItem);
                    }.bind(this), 5000);

                }
            }.bind(this));
                                          
            
            $(quoteGrid).kendoGrid({
                scrollable: "true",
                navigatable: "true",
                dataSource: this.dsQuotes,
                autobind: false,
                selectable: "row",
                sortable: true,
                //filterable: true,
                allowCopy: true,
                resizable: true,
                toolbar: [{ 'template': $('#quotesToolbarTemplate').html()}],
                editable :"inline",
                height: "100%",
                remove: function (e) {
                    this.objQuoteGrid.select("tr[data-uid='" + e.model.uid + "']");
                }.bind(this),
                beforeEdit: function (e) {
                    this.objQuoteGrid.select("tr[data-uid='" + e.model.uid + "']");
                }.bind(this),
                change: function (events) {
                    try {

                      this.lastDataItem = events.sender.dataItem(events.sender.select());

                    } catch (e) {
                    }
                }.bind(this), 

                columns: [
                    {
                        field: "Symbol",
                        title: "Symbol",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "Time",
                        title: "Time",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:HH:mm:ss.fff}"
                    },
                    {
                        field: "Bid",
                        title: "Bid",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N3}"
                        //template: '#=quotesController.formatPrice(Bid,Decimals,data)#'
                    },
                    {
                        field: "BidSize",
                        title: "Bid Size",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"

                    },
                    {
                        field: "BidExchange",
                        title: "Bid Exchange",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "Ask",
                        title: "Ask",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N3}"
                    },
                    {
                        field: "AskSize",
                        title: "Ask Size",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"

                    },
                    {
                        field: "AskExchange",
                        title: "Ask Exchange",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "MessageCount",
                        title: "Message Count",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"
                    }
                ]
            });


            this.viewModel = kendo.observable({

                newSymbol : "",
                onKeyPress: function (event) {
                    if (event.keyCode == 13) {
                        var symbol = this.symbolInput = $("#addSymbolQuote")[0].value;
                        tradeideas.subscribeToQuote(symbol);
                        this.viewModel.set('newSymbol', null);
                        event.preventDefault();
                    }
                }.bind(this),
                onClick: function (event) {

                    var symbol = this.viewModel.get('newSymbol');
                    this.viewModel.set('newSymbol', null);
                    tradeideas.subscribeToQuote(symbol);
                }.bind(this)
            });


            kendo.bind($("#quoteView"), this.viewModel);

            $(".k-grid-symbol" ,"#quoteGrid").bind("click", function (ev) {

                tradeideas.subscribeToQuote("FB");
                tradeideas.subscribeToQuote("GOOG");
                tradeideas.subscribeToQuote("AAPL");
                tradeideas.subscribeToQuote("F");
                tradeideas.subscribeToQuote("VZ");


            });

            //$("#quoteGrid .k-grid-content").css({
            //    "overflow-y": "hidden"
            //});

            this.objQuoteGrid = $(quoteGrid).data("kendoGrid");

            //this.objQuoteGrid.table.on("keydown", function (e) {
            //    if (e.keyCode == 46) {
            //        this.objQuoteGrid.removeRow(grid.select());
            //    }
            //});

            this.objQuoteGrid.wrapper.toggleClass("no-scrollbar");
                                  
            this.resize();
            
            tradeideas.setQuotesCallbacks(this.quotesCallback.bind(this));

        } catch (error) {

            tradeideas.errorHandler(error);
        }

        $(window).resize(function () {

            this.resize();

        }.bind(this));

    }

    formatPrice(priceElement, Decimals, data) {

        var direction = Math.floor(Math.random() * 3) + 1;

        if (direction === 1) {
            return "<span class='positiveColor'>" + kendo.toString(priceElement, "N" + Decimals) + "</span>";
        } else if (direction === 2)
            return "<span class='positiveColor'>" + kendo.toString(priceElement, "N" + Decimals) + "</span>";
        else {
            return "<span class='negativeColor'>" + kendo.toString(priceElement, "N" + Decimals) + "</span>";
        }
        
        //if (direction == 1) {
        //    return "<span class='positiveColor fas fa-surprise'>&nbsp;" +  kendo.toString( Bid, "N6") + "</span>";
        //} else if (direction === 2)
        //    return "<span class='positiveColor fas fa-long-arrow-alt-up'>&nbsp;" + kendo.toString(Bid, "N6") + "</span>";
        //else {
        //    return "<span class='negativeColor fas fa-long-arrow-alt-down'>&nbsp;" + kendo.toString(Bid, "N6") + "</span>";
        //}
    }

    toggle() {

        mainViewerElement.toggle();
    }

    resize() {

        if(this.objQuoteGrid)
            this.objQuoteGrid.resize();

    }
    
    
    quotesCallback(msg) {
       
        var item = this.objQuoteGrid.dataSource.get(msg.Data[0]);

        if (item) {
            item.MessageCount++;
            item.set('Time', new Date(msg.Data[1]));
            item.set('Bid', msg.Data[2]);
            item.set('BidSize', msg.Data[3]);
            item.set('BidExchange', msg.Data[4]);
            item.set('Ask', msg.Data[5]);
            item.set('AskSize', msg.Data[6]);
            item.set('AskExchange', msg.Data[7]);
            item.set('MessageCount', item.MessageCount );
        }
        else {

            var quote = {
                "Symbol": msg.Data[0],
                "Time": new Date(msg.Data[1]),
                "Bid": msg.Data[2],
                "BidSize": msg.Data[3],
                "BidExchange": msg.Data[4],
                "Ask": msg.Data[5],
                "AskSize": msg.Data[6],
                "AskExchange": msg.Data[7],
                "MessageCount": 1
            };
            this.objQuoteGrid.dataSource.pushUpdate(quote);
        }

    }

}

export {QuotesController as default};
