﻿import { tradeideas } from "../Server/tradeideas"
import KendoCustomBinder from "../Application/KendoCustomBinder"

class LoginController {
    
    constructor(application){
        this.application = application
    }

    init(loginElement) {

        try {
           
            var loginModalCustomBinder = new KendoCustomBinder();

            this.validator = loginElement.kendoValidator().data("kendoValidator");
            this.loginViewModel = kendo.observable({
                errorMessage: "",
                userNameLabel: "Username",
                passwordLabel: "Password",
                loginButtonLabel: "Login",
                onKeyPress: function (event) {

                    if (event.keyCode == 13) {
                        event.preventDefault();
                        this.doLogon(event);
                    }

                    return true;

                }.bind(this),
                loginClickHandler: function (event) {

                    this.doLogon(event);

                    event.preventDefault();

                    return false;

                }.bind(this),
                isVisible: false
            });


            kendo.bind(loginElement, this.loginViewModel);


            this.loginWindow = loginElement.data("kendoWindow");


            this.loginWindow.center().open();

            this.passwordInput = $("#passwordInput")[0];
            
            var username  = this.getParameterByName("username");
            var password  = this.getParameterByName("password");
        
            //if (this.username && this.password) {
            //    $("#spinner").toggleClass("k-loading-image");
            //    lynx.logon(this.username, this.password, _logonCallback);
            //}

            //$("#myButton").kendoButton({
            //    imageUrl: "../../Images/aboutyou-icon-1.svg"
            //});
          

        } catch (error) {

            tradeideas.errorHandler(error);
        }

    }


    getParameterByName(name) {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }


    doLogon(event) {

        if (this.validator.validate()) {
            this.username = event.data.username;
            var pwd = this.passwordInput.value;
            $("#spinner").toggleClass("k-loading-image");
           tradeideas.logon(this.username, pwd, this.logonCallback.bind(this));
           
        }
    }


    logonCallback(status,data) {

        if (status =="OK") {
                  
            this.loginViewModel.set('isVisible', false);
            this.loginViewModel.set('errorMessage', '');
            $("#loginModal").data("kendoWindow").close();

            this.application.statusBarController.updateUserName(this.username);
            this.application.initViews();

            $("#mainApplication").children().hide();
            $("#mainApplication").show();

        }
        else {

            switch (data.status) {
                case 400:
                    this.loginViewModel.set('password', "");
                    this.loginViewModel.set('isMessageVisible', true);
                    this.loginViewModel.set('errorMessage', "Invalid username/password, please try again.");
                    break;
                case 401:
                    this.loginViewModel.set('password', "");
                    this.loginViewModel.set('isMessageVisible', true);
                    this.loginViewModel.set('errorMessage', "Invalid username/password, please try again.");
                    break;
                case 403:
                    this.loginViewModel.set('password', "");
                    this.loginViewModel.set('isMessageVisible', true);
                    this.loginViewModel.set('errorMessage', "Invalid username/password, please try again.");
                    break;
            }
        }

        $("#spinner").toggleClass("k-loading-image");
     
    }

}

export {LoginController as default};