import { tradeideas } from "../../Server/tradeideas"

class BrokerUserSessionsController {
    constructor() {

    }

    init() {

        try {

            this.dsBrokerUserSessions = new kendo.data.DataSource(
                {
                    schema: {
                        model: {
                            id: "id",
                            fields: {
                                ApplicationName: {type: "string" }, 
                                ApplicationVersion: {type: "string" }, 
                                Environment: {type: "string" }, 
                                TargetInstance: { type: "string" },
                                Connected: { type: "bool" },
                                BrokerUserId: { type: "number" },
                                UserName: { type: "string" },
                                UserSessionId: { type: "number" },
                                NextOrderId: { type: "number" },
                                LocalOrders: { type: "number" },
                                BrokerUserSessionId: { type: "number" },
                                StatusType: { type: "string" },
                                BrokerUserSessionCreatedWhen: { type: "date" },
                                BrokerUserSessionUpdatedWhen: { type: "date" },
                            }
                        }
                    },
                    batch: false
                });


            $(brokerUserSessionsGrid).kendoGrid({
                scrollable: "true",
                navigatable: "true",
                dataSource: this.dsBrokerUserSessions,
                autobind: false,
                selectable: "row",
                sortable: true,
                filterable: { mode: "menu"},
                allowCopy: true,
                resizable: true,
                toolbar: [{ 'template': $('#brokerActiveUserSessionToolbarTemplate').html() }],
                editable: "inline",
                height: "100%",
                columns: [
                    {
                        field: "ApplicationName",
                        title: "Application",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "ApplicationVersion",
                        title: "Version",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "Environment",
                        title: "Environment",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "TargetInstance",
                        title: "Instance",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "Connected",
                        title: "Connected",
                        attributes: { class: "grid-data-center" },
                        //template: '<input type="checkbox" #= Connected ? \'checked="checked"\' : "" # class="chkbx k-checkbox k-checkbox-md k-rounded-md" />',
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "BrokerUserId",
                        title: "User Id",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                    },
                    {
                        field: "UserName",
                        title: "User Name",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },

                    },
                    {
                        field: "UserSessionId",
                        title: "User Ses.Id",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "NextOrderId",
                        title: "Order Id",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                    },
                    {
                        field: "LocalOrders",
                        title: "Local Orders",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },

                    },
                    {
                        field: "BrokerUserSessionId",
                        title: "Broker Ses.Id",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "StatusType",
                        title: "Status",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                    },
                    {
                        field: "BrokerUserSessionCreatedWhen",
                        title: "Created",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:HH:mm:ss.fff}",
                        width: "150px"
                    },
                    {
                        field: "BrokerUserSessionUpdatedWhen",
                        title: "Updated",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:HH:mm:ss.fff}",
                        width: "150px"
                    }
                ]
            });

            this.viewModel = kendo.observable({

                brokers:[{
                    broker:"Alpaca",
                },
                {
                    broker:"ETrade",
                    Id:2
                }],

                onBrokerSelected: function(){

                },
                
                onClick: function (event) {

                    kendo.ui.progress($("#mainApplication"), true);

                    this.objbrokerUserSessionsGrid.setDataSource([]);
                    this.dsBrokerUserSessions.data([]);

                    tradeideas.getbrokerUserSessions("Web01", function (result) {

                        if (result.Data) {

                            for (let i = 0; i < result.Data.length; i++) {

                                var record = result.Data[i];

                                if (record.LastConnectedWhen)
                                    record.LastConnectedWhen = new Date(record.LastConnectedWhen);

                                if (record.BrokerUserSessionCreatedWhen)
                                    record.BrokerUserSessionCreatedWhen = new Date(record.BrokerUserSessionCreatedWhen);

                                if (record.BrokerUserSessionUpdatedWhen)
                                    record.BrokerUserSessionUpdatedWhen = new Date(record.BrokerUserSessionUpdatedWhen);

                                this.dsBrokerUserSessions.add(record);
                            }
                        }
                    }.bind(this));

                    tradeideas.getbrokerUserSessions("Web02", function (result) {

                        if (result.Data) {

                            for (let i = 0; i < result.Data.length; i++) {

                                var record = result.Data[i];

                                if (record.LastConnectedWhen)
                                    record.LastConnectedWhen = new Date(record.LastConnectedWhen);

                                if (record.BrokerUserSessionCreatedWhen)
                                    record.BrokerUserSessionCreatedWhen = new Date(record.BrokerUserSessionCreatedWhen);

                                if (record.BrokerUserSessionUpdatedWhen)
                                    record.BrokerUserSessionUpdatedWhen = new Date(record.BrokerUserSessionUpdatedWhen);


                                this.dsBrokerUserSessions.add(record);

                            }
                        }

                        kendo.ui.progress($("#mainApplication"), false);
                        this.objbrokerUserSessionsGrid.setDataSource(this.dsBrokerUserSessions);

                    }.bind(this));


                    kendo.ui.progress($("#mainApplication"), false);

                }.bind(this)
            });
            kendo.bind($("#brokerUserSessionsView"), this.viewModel);

            this.objbrokerUserSessionsGrid = $(brokerUserSessionsGrid).data("kendoGrid");

            this.resize();

        } catch (error) {

            tradeideas.errorHandler(error);
        }

        $(window).resize(function () {

            this.resize();

        }.bind(this));

    }


    toggle() {

        mainViewerElement.toggle();
    }

    resize() {
        if (this.objbrokerUserSessionsGrid)
            this.objbrokerUserSessionsGrid.resize();

    }

}

export { BrokerUserSessionsController as default };