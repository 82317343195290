import { tradeideas } from "../../Server/tradeideas"

class OrdersController {
    constructor(){
        
    }

    init() {

        try {

            this.dsOrders = new kendo.data.DataSource(
                {
                    schema: {
                        model: {
                            id: "id",
                            fields: {
                                id: {type: "number"},
                                tiUser: { type: "string" },
                                brokerId: {  type: "number" },
                                broker: { type: "string" },
                                brokerAccountIdentifier: { type: "string" },
                                positionId: { type: "number" },
                                replaceOrderId: { type: "number" },
                                replacing: { type: "boolean" },     
                                brokerParentOrderId: { type: "string" },
                                brokerOrderId: { type: "string" },
                                replacingBrokerOrderId: { type: "string" },
                                brokerOrderNumber: { type: "string" },
                                securityType: { type: "string" },
                                symbol: { type: "string" },
                                convertFromLocal: { type: "bool" },
                                localExecutionType: { type: "string" },
                                localOrderId: { type: "string" },
                                isEntry: { type: "bool" },
                                orderStatus: { type: "string" },
                                orderAction: { type: "string" },
                                term: { type: "string" },
                                orderType: { type: "string" },
                                priceType: { type: "string" },
                                stopPrice: { type: "number" },
                                limitPrice: { type: "number" },
                                price: { type: "number" },
                                offsetType: { type: "string" },
                                offsetValue: { type: "number" },
                                trailPrice: { type: "number" },
                                referenceTag: { type: "string" },
                                orderedQuantity: { type: "number" },
                                filledQuantity: { type: "number" },
                                averageExecutionPrice: { type: "number" },
                                estimatedCommission: { type: "number" },
                                macro: { type: "string" },
                                ocaGroup: { type: "string" },
                                strategyName: { type: "string" },
                                comments: { type: "string" },
                                errorMessage: { type: "string" },
                                goodTillTime: { type: "date" },
                                goodAfterTime: { type: "date" },
                                createdWhen: { type: "date" },
                                updatedWhen: { type: "date" },
                                executedWhen: {type: "date" }
                            }
                        }
                    },
                    batch: false
                });
                   
                this.dsOrderHistoryTemplate = 
                    {
                        schema: {
                            model: {
                                id: "id",
                                fields: {
                                    id: {type: "number"},
                                    historyType: { type: "number" },
                                    historyTypeDescription: {  type: "string" },
                                    orderId: { type: "number" },
                                    brokerOrderId: { type: "string" },
                                    localOrderId: { type: "string" },
                                    localExecutionType: { type: "string" },
                                    isEntry: { type: "bool" },
                                    orderStatus: { type: "string" },
                                    term: { type: "bool" },
                                    stopPrice: { type: "number" },
                                    limitPrice: { type: "number" },
                                    offsetType: { type: "string" },
                                    offsetValue: { type: "number" },
                                    trailPrice: { type: "number" },
                                    orderedQuantity: { type: "number" },
                                    filledQuantity: { type: "number" },
                                    averageExecutionPrice: { type: "number" },
                                    estimatedCommission: { type: "number" },
                                    comments: { type: "string" },
                                    errorMessage: { type: "string" },
                                    goodTillTime: { type: "date" },
                                    goodAfterTime: { type: "date" },
                                    historyWhen: { type: "date" }
                                }
                            }
                        },
                        batch: false
                    };

            
            $(ordersGrid).kendoGrid({
                scrollable: "true",
                navigatable: "true",
                autobind: false,
                selectable: "row",
                sortable: true,
                filterable: true,
                pageable: false,
                detailInit: this.orderHistoryInit.bind(this),
                allowCopy: true,
                resizable: false,
                toolbar: [{ 'template': $('#ordersToolbarTemplate').html()}],
                editable :"inline",
                height: "100%",
                columns: [
                    {
                        field: "id",
                        title: "Id",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "tiUser",
                        title: "User",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "broker",
                        title: "Broker",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                    },
                    {
                        field: "brokerOrderId",
                        title: "Order Id",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }

                    },
                    {
                        field: "symbol",
                        title: "Symbol",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "localExecutionType",
                        title: "Local Type",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "localOrderId",
                        title: "Local Id",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "isEntry",
                        title: "Entry",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "orderStatus",
                        title: "Status",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "orderAction",
                        title: "Action",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "term",
                        title: "Term",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "goodTillTime",
                        title: "Good Until",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:yyyy-MM-dd HH:mm:ss.fff}",
                        width: "200px"
                    },
                    {
                        field: "goodAfterTime",
                        title: "Good After",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:yyyy-MM-dd HH:mm:ss.fff}",
                        width: "200px"
                    },
                    {
                        field: "priceType",
                        title: "Price Type",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "price",
                        title: "Price",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N4}"
                    },
                    {
                        field: "orderedQuantity",
                        title: "Quantity",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N1}"
                    },
                    {
                        field: "filledQuantity",
                        title: "Filled",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N1}"
                    },
                    {
                        field: "averageExecutionPrice",
                        title: "AVG. Price",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N4}"
                    },
                    {
                        field: "createdWhen",
                        title: "Created",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:yyyy-MM-dd HH:mm:ss.fff}",
                        width: "200px"
                    },
                    {
                        field: "updatedWhen",
                        title: "Updated",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:yyyy-MM-dd HH:mm:ss.fff}",
                        width: "200px"
                    },
                    {
                        field: "executedWhen",
                        title: "Executed",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:yyyy-MM-dd HH:mm:ss.fff}",
                        width: "200px"
                    }
                ]
            });

            var currentDate = new Date();
            this.viewModel = kendo.observable({

                ordersUserName : "",
                ordersStatus : "",
                ordersFromVisible: true,
                ordersFromValue: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7),
                ordersToVisible: true,
                ordersToValue: currentDate,
               
                onClick: function (event) {

                    var startDate = this.viewModel.get('ordersFromValue');
                    var endDate = this.viewModel.get('ordersToValue');
                    var user = this.viewModel.get('ordersUserName');
                    var status = this.viewModel.get('ordersStatus');    
                    kendo.ui.progress($("#mainApplication"), true);

                    tradeideas.getOrders(user, status, startDate.toISOString(), endDate.toISOString(), function (result) {

                        this.objOrdersGrid.setDataSource([]);
                        this.dsOrders.data([]);

                        if (result.Data) {

                            for (let i = 0; i < result.Data.length; i++) {

                                var record = result.Data[i];
                
                                if( record.goodTillTime)
                                    record.goodTillTime =  new Date(record.goodTillTime)    ;   

                                if( record.goodAfterTime)
                                    record.goodAfterTime =  new Date(record.goodAfterTime)    ;   

                                if( record.createdWhen)
                                    record.createdWhen =  new Date(record.createdWhen)    ;   

                                if( record.updatedWhen)
                                    record.updatedWhen =  new Date(record.updatedWhen)    ;   

                                if( record.executedWhen)
                                    record.executedWhen =  new Date(record.executedWhen)    ;   

                                    this.dsOrders.add(record);

                            }

                            kendo.ui.progress($("#mainApplication"), false);
                            this.objOrdersGrid.setDataSource(this.dsOrders);
                        }
                        else {
                           alert("No Records Found");
                        }

                    }.bind(this));

                    kendo.ui.progress($("#mainApplication"), false);
                }.bind(this)
            });

            kendo.bind($("#ordersView"), this.viewModel);

            this.objOrdersGrid = $(ordersGrid).data("kendoGrid");

            this.resize();

        } catch (error) {

            tradeideas.errorHandler(error);
        }

        $(window).resize(function () {

            this.resize();

        }.bind(this));

    }

    

    orderHistoryInit(e) {

        tradeideas.getOrderHistory(e.data.SessionId, function (result) {


            // if (result.Obj  && result.Obj.length > 0) {


            //     for (let i = 0; i < result.Obj.length; i++) {
            //         var record = result.Obj[i];
            //         record.CreatedWhen = new Date(record.CreatedWhen);
            //     }

            //     this.dsMarketDataSubscriptionsTemplate.data =result.Obj ;

            //     var grid  = $("<div/>").appendTo(e.detailCell).kendoGrid({
            //         scrollable: "true",
            //         navigatable: "true",
            //         dataSource: new kendo.data.DataSource(this.dsMarketDataSubscriptionsTemplate),
            //         autobind: false,
            //         selectable: "column",
            //         sortable: true,
            //         resizable: true,
            //         allowCopy: true,
            //         columns: [
            //             {
            //                 field: "SessionId",
            //                 title: "Sesssion Id",
            //                 attributes: { class: "grid-data-right" },
            //                 headerAttributes: { class: "grid-header-center" },
            //                 format: "{0:N0}"
        
            //             },
            //             {
            //                 field: "SubscriptionId",
            //                 title: "Subscription Id",
            //                 attributes: { class: "grid-data-right" },
            //                 headerAttributes: { class: "grid-header-center" },
            //                 format: "{0:N0}"
            //             },
            //             {
            //                 field: "Symbol",
            //                 title: "Symbol",
            //                 attributes: { class: "grid-data-left" },
            //                 headerAttributes: { class: "grid-header-center" }
            //             },
            //             {
            //                 field: "SubscriptionAction",
            //                 title: "Subscription Action",
            //                 attributes: { class: "grid-data-center" },
            //                 headerAttributes: { class: "grid-header-center" }
            //             },
            //             {
            //                 field: "SubscriptionType",
            //                 title: "Subscription Type",
            //                 attributes: { class: "grid-data-center" },
            //                 headerAttributes: { class: "grid-header-center" }
            //             },
            //             {
            //                 field: "CreatedWhen",
            //                 title: "Created",
            //                 attributes: { class: "grid-data-center" },
            //                 headerAttributes: { class: "grid-header-center" },
            //                 format: "{0:yyyy-MM-dd HH:mm:ss.fff}",
            //                 width: "200px"
            //             }
            //         ]
            //     }).data("kendoGrid");

            //     kendo.ui.progress($("#mainApplication"), false);
            // }
            // else {
            //   // alert("No Records Found");
            //    return; 
            // }

        }.bind(this));
       
             
    }

    toggle() {

        mainViewerElement.toggle();
    }

    resize() {
      if(this.objOrdersGrid)
        this.objOrdersGrid.resize();

    }

}

export {OrdersController as default};