export default{
     tiBrokerServicesDomainREST : "https://bws.trade-ideas.com/BrokersWebService/v1",
     tiBrokerServicesDomainWS : "wss://bws.trade-ideas.com/BrokersWebService/v1",
     tiCompanyLogosPath : "https://bws.trade-ideas.com/static/CompanyLogos",
     tiWebSocketGatewayDomainREST : "https://bws.trade-ideas.com/TradeIdeasWebService/v1",
     tiWebSocketGatewayDomainWS : "wss://bws.trade-ideas.com/TradeIdeasWebService/v1",

    //tiBrokerServicesDomainREST : "https://api-dev.trade-ideas.com/BrokersWebService/v1",
    //tiBrokerServicesDomainWS : "wss://api-dev.trade-ideas.com/BrokersWebService/v1",
    //tiCompanyLogosPath : "https://api-dev.trade-ideas.com/static/CompanyLogos",

    //tiWebSocketGatewayDomainREST : "https://api-dev.trade-ideas.com/TradeIdeasWebService/v1",
    //tiWebSocketGatewayDomainWS : "wss://api-dev.trade-ideas.com/TradeIdeasWebService/v1",

    //tiWebSocketGatewayDomainREST : "https://localhost:44335/TradeIdeasWebService/v1",
    //tiWebSocketGatewayDomainWS : "wss://localhost:44335/TradeIdeasWebService/v1",


    //tiBrokerServicesDomainREST : "https://localhost:44307/BrokersWebService/v1",
    //tiBrokerServicesDomainWS : "wss://localhost:44307/BrokersWebService/v1",
    //tiBrokerServicesDomainREST : "https://api-dev.trade-ideas.com/BrokersWebService/v1",
    //tiBrokerServicesDomainWS : "wss://api-dev.trade-ideas.com/BrokersWebService/v1",
    //tiCompanyLogosPath : "https://api-dev.trade-ideas.com/static/CompanyLogos",


    //Dev
    //apiKey : "dNLykdCj9t9WgY5KahixviUE3YH2usbx"
    //Live
    apiKey : "q8DPrv1kjstfVqdXwZmjbKE1wxu2ybsG"
}