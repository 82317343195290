﻿import SkinsController from "../Controllers/Skins"
import MainMenuController from "../Controllers/MainMenu"
import StatusBarController from "../Controllers/StatusBar"
import LoginController from "../Controllers/Login"
import MarketDataController from "../Controllers/MarketData"
import UserSessionsController from "../Controllers/UserSessions"
import MarketDataSessionsController from "../Controllers/MarketDataSessions"
import BrokerServicesController from "../Controllers/BrokerServices"
import Reportings from "../Controllers/Reportings"
import MicroProxyController from "../Controllers/MicroProxy"
import WebSocketGatewayController from "../Controllers/WebSocketGateway"


class Application {
    constructor(){
        this.skinsController = new SkinsController();
        this.mainMenuController = new MainMenuController(this);
        this.statusBarController = new StatusBarController();
        this.loginController = new LoginController(this);
    }
    run() {
        String.prototype.format = function () {
            var formattedString = this;
            for (var index in arguments) {
                if (arguments.hasOwnProperty(index)) {
                    formattedString = formattedString.replace("{" + index + "}", arguments[index]);
                }
            }
            return formattedString;
        };
        // accounting.settings = {
        //     currency: {
        //         symbol: "$",
        //         format: { pos: "%s%v", neg: "(%s%v)", zero: "%s%v" },
        //         precision: 2
        //     },
        //     number: {
        //         precision: 0,
        //         thousand: ",",
        //         decimal: "."
        //     }
        // };

        this.skinsController.init($("#headView"), "flat");
        this.mainMenuController.init($("#mainNav"));
        this.statusBarController.init($("#statusBar"));
        this.loginController.init($("#loginModal"));


        $(window).on("beforeunload", function () { });

        $(window).resize(function () {

            this.resize();
        }.bind(this));

    }

    initViews(){
        this.userSessionsController = new UserSessionsController();
        this.marketDataSessionsController = new MarketDataSessionsController();
        this.brokerServicesController = new BrokerServicesController();    
        this.reportingsController = new Reportings();
        this.marketDataController = new MarketDataController();
        this.microProxyController = new MicroProxyController();
        this.webSocketGatewayController = new WebSocketGatewayController();
        this.userSessionsController.init();
        this.marketDataSessionsController.init();
        this.marketDataController.init();
        this.brokerServicesController.init();
        this.reportingsController.init(this.skinsController.curSkinName);
        this.microProxyController.init();
        this.webSocketGatewayController.init();
        this.skinsController.addSkinChangeEvent(this.reportingsController.skinChanged.bind(this.reportingsController))
  
    }

    getParameterByName(name) {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }
    
    sessionCallback(serverTime, logonDuration) {
        this.statusBarController.updateStatusBar(serverTime, logonDuration);
    }

    resize() {
        if(this.marketDataController){
            this.marketDataController.resize();
        }
        if(this.reportingsController){
            this.reportingsController.resize();
        }
        if(this.userSessionsController){
            this.userSessionsController.resize();
        }
        if(this.marketDataSessionsController){
            this.marketDataSessionsController.resize();
        }
        if(this.microProxyController){
            this.microProxyController.resize();
        }
        if(this.webSocketGatewayController){
            this.webSocketGatewayController.resize();
        }

    }

}

let application =  new Application();
$(function () {
    application.run();
});