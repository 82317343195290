import { tradeideas } from "../Server/tradeideas"

class MarketDataSessionsController {
    constructor(){
        
    }

    init() {

        try {

            this.dsMarketDataSessions = new kendo.data.DataSource(
                {
                    schema: {
                        model: {
                            id: "SessionId",
                            fields: {
                                SessionId: {type: "number"},
                                ApplicationId: { type: "number" },
                                UserName: {  type: "string" },
                                ThrottleMS: { type: "number" },
                                ActionResult: { type: "number" },
                                SubscriptionCount: {type: "number"},
                                CreatedWhen: { nullable: true, type: "date" },
                                EndedWhen: { nullable: true, type: "date" },
                            }
                        }
                    },
                    batch: false,
                    change: function (e) {
                    }
                });
                   
                this.dsMarketDataSubscriptionsTemplate = 
                    {
                        schema: {
                            model: {
                                id: "SubscriptionId",
                                fields: {
                                    SubscriptionId: {editable: false,type: "number"},
                                    SessionId: { type: "number" },
                                    SubscriptionAction: {  type: "string" },
                                    SubscriptionType: { type: "string" },
                                    Symbol: { type: "string" },
                                    CreatedWhen: { nullable: true, type: "date" }
                                }
                            }
                        },
                        batch: false
                    };

            
            $(marketDataSessionsGrid).kendoGrid({
                scrollable: "true",
                navigatable: "true",
                autobind: false,
                selectable: "row",
                sortable: true,
                //filterable: true,
                pageable: false,
                detailInit: this.marketDataSubscriptionsInit.bind(this),
                allowCopy: true,
                resizable: false,
                toolbar: [{ 'template': $('#marketDataSessionsToolbarTemplate').html()}],
                editable :"inline",
                height: "100%",
                columns: [
                    {
                        field: "SessionId",
                        title: "Sesssion Id",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"

                    },
                    {
                        field: "ApplicationName",
                        title: "Application",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"
                    },
                    {
                        field: "UserName",
                        title: "User",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                    },
                    {
                        field: "ThrottleMS",
                        title: "Throttle MS",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"
                    },
                    {
                        field: "SessionStatus",
                        title: "Status",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "SubscriptionCount",
                        title: "Subscriptions",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"
                    },
                    {
                        field: "CreatedWhen",
                        title: "Created",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:yyyy-MM-dd HH:mm:ss.fff}",
                        width: "200px"
                    },
                    {
                        field: "EndedWhen",
                        title: "Ended",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:yyyy-MM-dd HH:mm:ss.fff}",
                        width: "200px"

                    }
                ]
            });

            var currentDate = new Date();
            this.viewModel = kendo.observable({

                userName : "",

                mdSessionsFromVisible: true,
                mdSessionsFromValue: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1),
                mdSessionsToVisible: true,
                mdSessionsToValue: currentDate,
               
                onClick: function (event) {

                    kendo.ui.progress($("#mainApplication"), true);
                   
                    var startDate = this.viewModel.get('mdSessionsFromValue');
                    var endDate = this.viewModel.get('mdSessionsToValue');
                    var user = this.viewModel.get('userName').trim();


                    if( tradeideas.getUserName() != "TI_521" && ( user == null || user.length === 0))
                    {
                        alert("You must provide a user name for this request.");
                        kendo.ui.progress($("#mainApplication"), false);
                        return;
                    }
        

                    tradeideas.getMarketDataSessions(user, startDate.toISOString(), endDate.toISOString(), function (result) {

                        this.objMarketDataSessionsGrid.setDataSource([]);
                        this.dsMarketDataSessions.data([]);

                        if (result.Obj) {

                            for (let i = 0; i < result.Obj.length; i++) {

                                var record = result.Obj[i];

                                record.CreatedWhen = new Date(record.CreatedWhen);
                                if( record.EndedWhen)
                                    record.EndedWhen =  new Date(record.EndedWhen)    ;   
                                    this.dsMarketDataSessions.add(record);

                            }

                         
                            this.objMarketDataSessionsGrid.setDataSource(this.dsMarketDataSessions);
                        }
                        else {
                           alert("No Records Found");
                        }

                        kendo.ui.progress($("#mainApplication"), false);
                        
                    }.bind(this));

                }.bind(this)
            });

            kendo.bind($("#marketDataSessionsView"), this.viewModel);

            this.objMarketDataSessionsGrid = $(marketDataSessionsGrid).data("kendoGrid");
                                 
            this.resize();

        } catch (error) {

            tradeideas.errorHandler(error);
        }

        $(window).resize(function () {

            this.resize();

        }.bind(this));

    }

    marketDataSubscriptionsInit(e) {

        kendo.ui.progress($("#mainApplication"), true);

        tradeideas.getMarketDataSubscriptions(e.data.SessionId, function (result) {


            if (result.Obj  && result.Obj.length > 0) {


                for (let i = 0; i < result.Obj.length; i++) {
                    var record = result.Obj[i];
                    record.CreatedWhen = new Date(record.CreatedWhen);
                }

                this.dsMarketDataSubscriptionsTemplate.data =result.Obj ;

                var grid  = $("<div/>").appendTo(e.detailCell).kendoGrid({
                    scrollable: "true",
                    navigatable: "true",
                    dataSource: new kendo.data.DataSource(this.dsMarketDataSubscriptionsTemplate),
                    filterable: true,
                    autobind: false,
                    selectable: "column",
                    sortable: true,
                    resizable: true,
                    allowCopy: true,
                    height: "500px",
                    columns: [
                        {
                            field: "SessionId",
                            title: "Sesssion Id",
                            attributes: { class: "grid-data-right" },
                            headerAttributes: { class: "grid-header-center" },
                            format: "{0:N0}"
        
                        },
                        {
                            field: "SubscriptionId",
                            title: "Subscription Id",
                            attributes: { class: "grid-data-right" },
                            headerAttributes: { class: "grid-header-center" },
                            format: "{0:N0}"
                        },
                        {
                            field: "Symbol",
                            title: "Symbol",
                            attributes: { class: "grid-data-left" },
                            headerAttributes: { class: "grid-header-center" }
                        },
                        {
                            field: "SubscriptionAction",
                            title: "Subscription Action",
                            attributes: { class: "grid-data-center" },
                            headerAttributes: { class: "grid-header-center" }
                        },
                        {
                            field: "SubscriptionType",
                            title: "Subscription Type",
                            attributes: { class: "grid-data-center" },
                            headerAttributes: { class: "grid-header-center" }
                        },
                        {
                            field: "CreatedWhen",
                            title: "Created",
                            attributes: { class: "grid-data-center" },
                            headerAttributes: { class: "grid-header-center" },
                            format: "{0:yyyy-MM-dd HH:mm:ss.fff}",
                            width: "200px"
                        }
                    ]
                }).data("kendoGrid");

                
            }
            else {
              // alert("No Records Found");
               return; 
            }

            kendo.ui.progress($("#mainApplication"), false);

        }.bind(this));
       
             
    }

    toggle() {

        mainViewerElement.toggle();
    }

    resize() {
      if(this.objMarketDataSessionsGrid)
        this.objMarketDataSessionsGrid.resize();

    }

}

export {MarketDataSessionsController as default};