import { tradeideas } from "../Server/tradeideas"

class WebSocketGatewayController {
    constructor(){
        
    }

    init() {

        try {

            this.dsConnections = new kendo.data.DataSource(
                {
                    schema: {
                        model: {
                            id: "UserName",
                            fields: {
                                Server: {type: "string"},
                                AccountType: {type: "string"},
                                UserName: {type: "string"},
                                UserIpAddress: {type: "string"},
                                ApplicationId: { type: "number" },
                                ApplicationName: { type: "string" },
                                Destination: {  type: "string" },
                                MessagesReceived: {  type: "number" },
                                MessagesReceivedSize: {  type: "number" },
                                MessagesSent: {type: "number"},
                                MessagesSentSize: {  type: "number" },
                                ConnectedWhen: { nullable: true, type: "date" }
                            }
                        }
                    },
                    batch: false,
                    change: function (e) {
                    },
                    aggregate : [
                        {field: "Destination", aggregate: "count"},
                        {field: "MessagesReceived", aggregate: "sum"},
                        {field: "MessagesReceivedSize", aggregate: "sum"},
                        {field: "MessagesSent", aggregate: "sum"},
                        {field: "MessagesSentSize", aggregate: "sum"}
                    ],
                    group: [
                        {
                            field: "Server",
                            aggregates : [
                                {field: "Destination", aggregate: "count"},
                                {field: "MessagesReceived", aggregate: "sum"},
                                {field: "MessagesReceivedSize", aggregate: "sum"},
                                {field: "MessagesSent", aggregate: "sum"},
                                {field: "MessagesSentSize", aggregate: "sum"}
                            ],
                            }
                    ]
                });
                   
            
            $(webSocketGatewayGrid).kendoGrid({
                scrollable: "true",
                navigatable: "true",
                datasource:[],
                groupable:true,
                autobind: false,
                selectable: "row",
                sortable: true,
                filterable: true,
                pageable: false,
                allowCopy: true,
                resizable: false,
                toolbar: [{ 'template': $('#webSocketGatewayToolbarTemplate').html()}],
                editable :"inline",
                height: "100%",
                columns: [
                    {
                        field: "Server",
                        title: "Server",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        width: "100px"
                    },
                    {
                        field: "UserIpAddress",
                        title: "IP Address",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "AccountType",
                        title: "Type",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "UserName",
                        title: "User",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        width: "200px"
                    },
                    {
                        field: "ApplicationName",
                        title: "Application",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                    },
                    {
                        field: "Destination",
                        title: "Destination ",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        aggregates: ["count"],
                        groupFooterTemplate: "<div style=text-align:right>#= kendo.toString(count, 'n0') #<\/div>",
                        footerTemplate: "# if (data.Destination)  { # <div style=text-align:right>#=kendo.toString(data.Destination.count, 'N0')# </div> # } #"
                    },
                    {
                        field: "MessagesReceived",
                        title: "Msg. Received",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}",
                        aggregates: ["sum"],
                        groupFooterTemplate: "<div style=text-align:right>#= kendo.toString(sum, 'n0') #<\/div>",
                        footerTemplate: "# if (data.MessagesReceived)  { # <div style=text-align:right>#=kendo.toString(data.MessagesReceived.sum, 'N0')# </div> # } #"
                    },
                    {
                        field: "MessagesReceivedSize",
                        title: "Msg. Received Size",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}",
                        aggregates: ["sum"],
                        groupFooterTemplate: "<div style=text-align:right>#= kendo.toString(sum, 'n0') #<\/div>",
                        footerTemplate: "# if (data.MessagesReceivedSize)  { # <div style=text-align:right>#=kendo.toString(data.MessagesReceivedSize.sum, 'N0')# </div> # } #"
                    },
                    {
                        field: "MessagesSent",
                        title: "Msg. Sent",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}",
                        aggregates: ["sum"],
                        groupFooterTemplate: "<div style=text-align:right>#= kendo.toString(sum, 'n0') #<\/div>",
                        footerTemplate: "# if (data.MessagesSent)  { # <div style=text-align:right>#=kendo.toString(data.MessagesSent.sum, 'N0')# </div> # } #"
                    },
                    {
                        field: "MessagesSentSize",
                        title: "Msg. Sent Size",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}",
                        aggregates: ["sum"],
                        groupFooterTemplate: "<div style=text-align:right>#= kendo.toString(sum, 'n0') #<\/div>",
                        footerTemplate: "# if (data.MessagesSentSize)  { # <div style=text-align:right>#=kendo.toString(data.MessagesSentSize.sum, 'N0')# </div> # } #"
                    },
                    {
                        field: "ConnectedWhen",
                        title: "Connected When",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:yyyy-MM-dd HH:mm:ss.fff}",
                        width: "180px"
                    }
                ]
            });

            var currentDate = new Date();
            this.viewModel = kendo.observable({

                userName : "",

                userSessionsFromVisible: true,
                userSessionsFromValue: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1),
                userSessionsToVisible: true,
                userSessionsToValue: currentDate,
               
                onClick: function (event) {


                    kendo.ui.progress($("#mainApplication"), true);

                    var user = this.viewModel.get('webSocketGatewayUserName');
                    
                    this.objWebSocketGatewayGrid.setDataSource([]);
                    this.dsConnections.data([]);

                    tradeideas.getWebSocketGatewayActiveConnections("Web01", function (result) {

                        if (result) {

                            for (let i = 0; i < result.length; i++) {

                                var record = result[i];

                                if( record.ConnectedWhen)
                                    record.ConnectedWhen = new Date(record.ConnectedWhen);

                                this.dsConnections.add(record);
                            }
                        }

                    }.bind(this));

                    tradeideas.getWebSocketGatewayActiveConnections("Web02", function (result) {

                        if (result) {

                            for (let i = 0; i < result.length; i++) {

                                var record = result[i];

                                if( record.ConnectedWhen)
                                    record.ConnectedWhen = new Date(record.ConnectedWhen);

                                this.dsConnections.add(record);
                            }
                        }

                        this.objWebSocketGatewayGrid.setDataSource(this.dsConnections);

                        kendo.ui.progress($("#mainApplication"), false);
    
                    }.bind(this));

                }.bind(this)
            });

            kendo.bind($("#webSocketGatewayView"), this.viewModel);

            this.objWebSocketGatewayGrid = $(webSocketGatewayGrid).data("kendoGrid");
                                 
            this.resize();

        } catch (error) {

            tradeideas.errorHandler(error);
        }

        $(window).resize(function () {

            this.resize();

        }.bind(this));

    }


    toggle() {

        mainViewerElement.toggle();
    }

    resize() {
      if(this.objWebSocketGatewayGrid)
        this.objWebSocketGatewayGrid.resize();

    }

}

export {WebSocketGatewayController as default};