﻿class KendoCustomBinder {

    // As the Kendo window as Modal can not be closed declaratively 
    // so custom binding is needed for opening the modal at center 
    // & closing the modal w/o accessing the view
    bindModalWindowEvents(modalWindow) {

        kendo.data.binders.widget[modalWindow] = kendo.data.Binder.extend({
            refresh: function () {
                var value = this.bindings[modalWindow].get();
                if (value) {
                    if (modalWindow !== "loginWindow") {
                        this.element.open();
                    }
                    this.element.center();
                } else {
                    this.element.close();
                }
            }.bind(this)
        });
    }
}
export { KendoCustomBinder as default };



