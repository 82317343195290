﻿import { tradeideas } from "../../Server/tradeideas"
class TradesController {


    constructor(){
        this.latestData = {}
    }
    init() {

        try {


            this.dsTrades = new kendo.data.DataSource(
                {
                    schema: {
                        model: {
                            id: "Symbol",
                            fields: {
                                Symbol: { editable: false },
                                Time: { nullable: true, type: "date" },
                                TickVolume: { nullable: true, type: "number" },
                                Size: { nullable: true, type: "number" },
                                TotalVolume: { nullable: true, type: "number" },
                                Price: { nullable: true, type: "number" },
                                ReportingExchange: { nullable: true },
                                MessageCount: { nullable: true, type: "number" }
                            }
                        }
                    },
                    batch: false,
                    change: function (e) {
                    }
                });

            $("#tradeGrid").on("keydown", function (event) {

                if (event.key == "Delete") {

                    //dataItem = objQuoteGrid.dataItem(objQuoteGrid.select());

                    tradeideas.unsubscribeFromTrade(this.lastDataItem.Symbol);

                    setTimeout(function (e) {
                        this.objTradeGrid.dataSource.remove(this.lastDataItem);
                    }.bind(this), 5000);

                }
            }.bind(this));


            $(tradeGrid).kendoGrid({
                scrollable: "true",
                navigatable: "true",
                dataSource: this.dsTrades,
                autobind: false,
                selectable: "row",
                sortable: true,
                //filterable: true,
                allowCopy: true,
                resizable: true,
                toolbar: [{ 'template': $('#tradesToolbarTemplate').html() }],
                editable: "inline",
                height: "100%",
                remove: function (e) {
                    this.objTradeGrid.select("tr[data-uid='" + e.model.uid + "']");
                }.bind(this),
                beforeEdit: function (e) {
                    this.objTradeGrid.select("tr[data-uid='" + e.model.uid + "']");
                }.bind(this),
                change: function (events) {
                    try {

                        this.lastDataItem = events.sender.dataItem(events.sender.select());

                    } catch (e) {
                    }
                }.bind(this), 
                columns: [
                    {
                        field: "Symbol",
                        title: "Symbol",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "Time",
                        title: "Time",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:HH:mm:ss.fff}"
                    },
                    {
                        field: "TickVolume",
                        title: "Tick Volume",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"
                    },
                    {
                        field: "Size",
                        title: "Size",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"

                    },
                    {
                        field: "TotalVolume",
                        title: "Total Volume",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"

                    },
                    {
                        field: "Price",
                        title: "Price",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N3}"
                    },
                    {
                        field: "ReportingExchange",
                        title: "Reporting Exchange",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "MessageCount",
                        title: "Message Count",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"
                    }
                ]
            });


            this.viewModel = kendo.observable({

                newSymbol: "",
                onKeyPress: function (event) {
                    if (event.keyCode == 13) {
                        var symbol = this.symbolInput = $("#addSymbolTrade")[0].value;
                        tradeideas.subscribeToTrade(symbol);
                        this.viewModel.set('newSymbol', null);
                        event.preventDefault();
                    }
                }.bind(this),
                onClick: function (event) {

                    var symbol = this.viewModel.get('newSymbol');
                    this.viewModel.set('newSymbol', null);
                    tradeideas.subscribeToTrade(symbol);
                }.bind(this)
            });


            kendo.bind($("#tradeView"), this.viewModel);

            $(".k-grid-symbol", "#tradeGrid").bind("click", function (ev) {

                tradeideas.subscribeToTrade("FB");
                tradeideas.subscribeToTrade("GOOG");
                tradeideas.subscribeToTrade("AAPL");
                tradeideas.subscribeToTrade("F");
                tradeideas.subscribeToTrade("VZ");


            });

            //$("#tradeGrid .k-grid-content").css({
            //    "overflow-y": "hidden"
            //});

            this.objTradeGrid = $(tradeGrid).data("kendoGrid");

            //this.objTradeGrid.table.on("keydown", function (e) {
            //    if (e.keyCode == 46) {
            //        this.objTradeGrid.removeRow(grid.select());
            //    }
            //});

            this.objTradeGrid.wrapper.toggleClass("no-scrollbar");

            this.resize();


            tradeideas.setTradesCallbacks(this.tradesCallback.bind(this));


        } catch (error) {

            tradeideas.errorHandler(error);
        }

        $(window).resize(function () {

            this.resize();

        }.bind(this));

    }

    formatPrice(priceElement, Decimals, data) {

        var direction = Math.floor(Math.random() * 3) + 1;

        if (direction === 1) {
            return "<span class='positiveColor'>" + kendo.toString(priceElement, "N" + Decimals) + "</span>";
        } else if (direction === 2)
            return "<span class='positiveColor'>" + kendo.toString(priceElement, "N" + Decimals) + "</span>";
        else {
            return "<span class='negativeColor'>" + kendo.toString(priceElement, "N" + Decimals) + "</span>";
        }
    }

    toggle() {

        mainViewerElement.toggle();
    }

    resize() {

        if( this.objTradeGrid)
            this.objTradeGrid.resize();

    }


   
    tradesCallback(msg) {

            var item = this.objTradeGrid.dataSource.get(msg.Data[0]);
            
            if (item) {
                item.MessageCount++;
                item.set('Time', new Date(msg.Data[1]));
                item.set('TickVolume', msg.Data[2]);
                item.set('Size', msg.Data[3]);
                item.set('TotalVolume', msg.Data[4]);
                item.set('Price', msg.Data[5]);
                item.set('ReportingExchange', msg.Data[6]);
                item.set('MessageCount', item.MessageCount);
            }
            else {

                var trade = {
                    "Symbol": msg.Data[0],
                    "Time": new Date(msg.Data[1]),
                    "TickVolume": msg.Data[2],
                    "Size": msg.Data[3],
                    "TotalVolume": msg.Data[4],
                    "Price": msg.Data[5],
                    "ReportingExchange": msg.Data[6],
                    "MessageCount": 1
                };
                this.objTradeGrid.dataSource.pushUpdate(trade);
            }
    }

}

export {TradesController as default};