import { tradeideas } from "../Server/tradeideas"

class ReportingsController {
    constructor(){
        
    }

    init(skinName) {

        try {

            $('#reportingGridContainer').hide();

            $(reportingToolbar).kendoToolBar({
                items:[
                    {
                        template:$('#reportToolbarTemplate').html()
                    }
                ]
            })
            var currentDate = new Date();
            this.chartData = {
                TradingDay: [],
                UserCount: [],
                SymbolCount: []
            }

            this.reportTypes = [
                {
                    Id:1,
                    display:"Daily Users"
                },
                {
                    Id:2,
                    display:"Daily Symbols"
                }
            ];

            if( tradeideas.getUserName() == "TI_521")
            {
                this.reportTypes.push( {Id:3,display:"User & Symbol Summary"});
            }


            this.toolbarViewModel = kendo.observable({
                user:'',
                symbol:'',
                mdEndDateValue: currentDate,
                summaryType:1,
                chartType: 1,
                chartTypes:[{
                        display:"Line",
                        Id:1
                    },
                    {
                        display:"Area",
                        Id:2
                    },
                    {
                        display:"Bar",
                        Id:3
                    },
                    {
                        display:"Column",
                        Id:4
                    }],
                reportTypes: this.reportTypes,
                mdStartDateValue: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1),
                onReportsTypeSelected: function(){
                $('#reportingGridContainer').hide();
                $('#reportingChart').hide(); 
                 switch (this.toolbarViewModel.summaryType)
                 {
                     case 1:
                        this.objKendoChart.setOptions({
                            series: [{
                                field: "UserCount",
                                name: this.getChartName()
                            }],
                            title: {
                                text: this.getChartName()
                            }
                        })
                        $('#reportingChart').show();
                     break;
                     case 2: 
                         this.objKendoChart.setOptions({
                            series: [{
                                field: "SymbolCount",
                                name: this.getChartName()
                            }],
                            title: {
                                text: this.getChartName()
                            }
                        })
                        $('#reportingChart').show();
                     break;
                     case 3:
                        $('#reportingGridContainer').show(); 

                        break;       
                  }           
                    this.objKendoChart.dataSource.read();
                    this.objKendoChart.refresh();
                }.bind(this),
                loadChartClick: function(){

                    //kendo.ui.progress($("#mainApplication"), true);
                    //kendo.ui.progress($("#reportingGridContainer"), true);
                    kendo.ui.progress($("#mainApplication"), true);
                    

                    switch (this.toolbarViewModel.summaryType)
                    {
                        case 1:
                        case 2:
                            tradeideas.getMarketDataStatisticsReport(this.toolbarViewModel.mdStartDateValue.toISOString().slice(0,10), this.toolbarViewModel.mdEndDateValue.toISOString().slice(0,10), this.toolbarViewModel.user, this.toolbarViewModel.symbol, function(data){
                                if(data.Obj){

                                    let lastYear = null;
                                    for(var i=0;i<data.Obj.length;i++){
                                        let date = new Date(data.Obj[i].TradingDay)
                                        data.Obj[i].TradingDay = (date.getMonth() + 1) + "/" + date.getDate()
                                        if(lastYear!=null && date.getFullYear()!=lastYear){
                                            data.Obj[i].TradingDay += "/" + date.getFullYear()
                                        }
                                        lastYear=date.getFullYear()
                                    }
                                    this.objKendoChart.setDataSource(data.Obj);
                                }
                                else{
                                    alert("No Records Found")
                                }
                                kendo.ui.progress($("#mainApplication"), false);
                            }.bind(this))
                        
                            break;
                        case 3:

                            tradeideas.getMarketDataUserSummaryReport(this.toolbarViewModel.mdStartDateValue.toISOString().slice(0,10), this.toolbarViewModel.mdEndDateValue.toISOString().slice(0,10), this.toolbarViewModel.user, this.toolbarViewModel.symbol, function(data){
    
                                this.objReportingGrid1.setDataSource([]);
                                if(data.Obj){
                                    this.objReportingGrid1.setDataSource(data.Obj);
                                }
                                else {
                                   alert("No Records Found");
                                }
                                kendo.ui.progress($("#mainApplication"), false);

                            }.bind(this))

                            tradeideas.getMarketDataSymbolSummaryReport(this.toolbarViewModel.mdStartDateValue.toISOString().slice(0,10), this.toolbarViewModel.mdEndDateValue.toISOString().slice(0,10), this.toolbarViewModel.user, this.toolbarViewModel.symbol, function(data){
    
                                this.objReportingGrid2.setDataSource([]);
                                if(data.Obj){
                                    this.objReportingGrid2.setDataSource(data.Obj);
                                }
                                else {
                                   alert("No Records Found");
                                }

                                kendo.ui.progress($("#mainApplication"), false);

                            }.bind(this))
                         break;

                    }

                    //kendo.ui.progress($("#mainApplication"), false);
                    //kendo.ui.progress($("#reportingGridContainer"), false);
                    

                    
                    
                }.bind(this),
                onChartTypeSelect: function(){
                    this.renderChart();
                }.bind(this)

            })
            kendo.bind($("#reportingToolbar"), this.toolbarViewModel);
            this.reportingToolbarObj = $(reportingToolbar).data("kendoToolBar");

            $("#reportingChart").kendoChart({
                theme: skinName,
                title: {
                    text: this.getChartName()
                },
                legend: {
                    position: "bottom"
                },
                seriesDefaults: {
                    type: "line",
                    style: "smooth"
                },
                tooltip: {
                    visible: true,
                    template: "#= series.name #: #=kendo.format('{0:n0}', value ) #"
                },
                categoryAxis: {
                    field: "TradingDay"
                },
                valueAxis: [{
                    labels: {
                        format: "{0:n0}"
                    }
                }],
                series: [{
                    field: "UserCount",
                    name: this.getChartName(),
                }],
              
            });

            this.objKendoChart = $(reportingChart).data("kendoChart");
            this.objKendoChart.resize();
        
            $("#reportingGrid1").kendoGrid({
                scrollable: "true",
                navigatable: "true",
                autobind: false,
                selectable: "row",
                sortable: true,
                filterable: true,
                pageable: false,
                allowCopy: true,
                resizable: false,
                height: "100%",
                columns: [
                    {
                        field: "UserName",
                        title: "User",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }            
                    },
                    {
                        field: "Symbols",
                        title: "Symbols",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"
                     },
                    {
                        field: "Subscriptions",
                        title: "Subscriptions",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"
                     }
                ]
            });

            this.objReportingGrid1 = $(reportingGrid1).data("kendoGrid");
            
            $("#reportingGrid2").kendoGrid({
                scrollable: "true",
                navigatable: "true",
                autobind: false,
                selectable: "row",
                sortable: true,
                filterable: true,
                pageable: false,
                allowCopy: true,
                resizable: false,
                height: "100%",
                columns: [
                    {
                        field: "Symbol",
                        title: "Symbol",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }            
                    },
                    {
                        field: "Users",
                        title: "Users",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"
                    },
                    {
                        field: "Subscriptions",
                        title: "Subscriptions",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"
                     }
                ]
            });

            this.objReportingGrid2 = $(reportingGrid2).data("kendoGrid");

            this.resize();

        } catch (error) {

            tradeideas.errorHandler(error);
        }

        $(window).resize(function () {

            this.resize();

        }.bind(this));

    }

    renderChart(){
        switch(this.toolbarViewModel.chartType){
            
            case 1:
                this.objKendoChart.setOptions({
                    seriesDefaults: {
                        type: "line",
                        style: "smooth"
                    }
                })
                this.objKendoChart.dataSource.read();
                this.objKendoChart.refresh();
                break;
            case 2:
                this.objKendoChart.setOptions({
                    seriesDefaults: {
                        type: "area",
                        area: {
                            line: {
                                style: "smooth"
                            }
                        }
                    }
                })
                this.objKendoChart.dataSource.read();
                this.objKendoChart.refresh();
                break;
            case 3:
                this.objKendoChart.setOptions({
                    seriesDefaults: {
                        type: "bar"
                    },
                })
                this.objKendoChart.dataSource.read();
                this.objKendoChart.refresh();
                break;
            case 4:
                this.objKendoChart.setOptions({
                    seriesDefaults: {
                        type: "column"
                    },
                })
                this.objKendoChart.dataSource.read();
                this.objKendoChart.refresh();
                break;
        }
    }

    resize() {
      if(this.reportingToolbarObj)
        this.reportingToolbarObj.resize(true);
      if(this.objKendoChart)
          this.objKendoChart.resize();
      if( this.objReportingGrid1)   
          this.objReportingGrid1.resize();
      if( this.objReportingGrid2)   
          this.objReportingGrid2.resize();

    }

    getChartName(){
        if(this.toolbarViewModel.summaryType==1){
            return "Daily Users"
        }
        else{
            return "Daily Symbols"
        }
    }

    skinChanged(skinName){
        this.objKendoChart.setOptions({
            theme: skinName
        })
        this.objKendoChart.dataSource.read();
        this.objKendoChart.refresh();
    }

}

export {ReportingsController as default};
