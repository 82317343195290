import { tradeideas } from "../Server/tradeideas"

class UserSessionsController {
    constructor(){
        
    }

    init() {

        try {

            this.dsUserSessions = new kendo.data.DataSource(
                {
                    schema: {
                        model: {
                            id: "UserSessionId",
                            fields: {
                                UserSessionId: {type: "number"},
                                ApplicationId: { type: "number" },
                                ApplicationName: {  type: "string" },
                                ApplicationVersion: {  type: "string" },
                                UserName: {  type: "string" },
                                StatusId: {type: "number"},
                                StatusDescription: {  type: "string" },
                                CreatedWhen: { nullable: true, type: "date" },
                                EndedWhen: { nullable: true, type: "date" },
                                MarketDataSessionId: {type: "number"},
                                MarketDataSessionThrottleMS: { type: "number" },
                                MarketDataSessionSubscriptionCount: {type: "number"},
                                MarketDataSessionCreatedWhen: { nullable: true, type: "date" },
                                MarketDataSessionEndedWhen: { nullable: true, type: "date" },
                            }
                        }
                    },
                    batch: false,
                    change: function (e) {
                    }
                });
                   
            
            $(userSessionsGrid).kendoGrid({
                scrollable: "true",
                navigatable: "true",
                autobind: false,
                selectable: "row",
                sortable: true,
                filterable: true,
                pageable: false,
                allowCopy: true,
                resizable: false,
                toolbar: [{ 'template': $('#userSessionDetailsToolbarTemplate').html()}],
                editable :"inline",
                height: "100%",
                columns: [
                    {
                        field: "UserSessionId",
                        title: "Id",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"
                    },
                    {
                        field: "StatusDescription",
                        title: "Status",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                    },
                    {
                        field: "ApplicationName",
                        title: "Application",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                    },
                    {
                        field: "ApplicationVersion",
                        title: "Version",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                    },
                    {
                        field: "UserName",
                        title: "User",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                    },
                    {
                        field: "CreatedWhen",
                        title: "Created",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:yyyy-MM-dd HH:mm:ss.fff}",
                        width: "180px"
                    },
                    {
                        field: "EndedWhen",
                        title: "Ended",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:yyyy-MM-dd HH:mm:ss.fff}",
                        width: "180px"

                    },
                    {
                        field: "MarketDataSessionId",
                        title: "MD-Id",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"

                    },
                    {
                        field: "MarketDataSessionThrottleMS",
                        title: "MD-Throttle MS",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"

                    },
                    {
                        field: "MarketDataSessionSubscriptionCount",
                        title: "MD-Subscriptions",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "MarketDataSessionCreatedWhen",
                        title: "MD-Created",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:yyyy-MM-dd HH:mm:ss.fff}",
                        width: "180px"
                    },
                    {
                        field: "MarketDataSessionEndedWhen",
                        title: "MD-Ended",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:yyyy-MM-dd HH:mm:ss.fff}",
                        width: "180px"

                    }
                ]
            });

            var currentDate = new Date();
            this.viewModel = kendo.observable({

                userName : "",

                userSessionsFromVisible: true,
                userSessionsFromValue: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1),
                userSessionsToVisible: true,
                userSessionsToValue: currentDate,
               
                onClick: function (event) {


                    kendo.ui.progress($("#mainApplication"), true);

                    var startDate = this.viewModel.get('userSessionsFromValue');
                    var endDate = this.viewModel.get('userSessionsToValue');
                    var user = this.viewModel.get('userName');

                    if( tradeideas.getUserName() != "TI_521" && ( user == null || user.length === 0))
                    {
                        alert("You must provide a user name for this request.");
                        kendo.ui.progress($("#mainApplication"), false);
                        return;
                    }

                    tradeideas.getUserSessions(user, startDate.toISOString(), endDate.toISOString(), function (result) {

                        this.objUserSessionsGrid.setDataSource([]);
                        this.dsUserSessions.data([]);

                        if (result.Obj) {

                            for (let i = 0; i < result.Obj.length; i++) {

                                var record = result.Obj[i];

                                if( record.CreatedWhen)
                                    record.CreatedWhen = new Date(record.CreatedWhen);
                                if( record.EndedWhen)
                                    record.EndedWhen =  new Date(record.EndedWhen);  
                                if( record.MarketDataSessionCreatedWhen)
                                    record.MarketDataSessionCreatedWhen =  new Date(record.MarketDataSessionCreatedWhen);   
                                if( record.MarketDataSessionEndedWhen)
                                    record.MarketDataSessionEndedWhen =  new Date(record.MarketDataSessionEndedWhen);   



                                this.dsUserSessions.add(record);

                            }

                            kendo.ui.progress($("#mainApplication"), false);
                            this.objUserSessionsGrid.setDataSource(this.dsUserSessions);
                        }
                        else {
                           alert("No Records Found");
                        }

                        kendo.ui.progress($("#mainApplication"), false);

                    }.bind(this));

                }.bind(this)
            });

            kendo.bind($("#userSessionsView"), this.viewModel);

            this.objUserSessionsGrid = $(userSessionsGrid).data("kendoGrid");
                                 
            this.resize();

        } catch (error) {

            tradeideas.errorHandler(error);
        }

        $(window).resize(function () {

            this.resize();

        }.bind(this));

    }


    toggle() {

        mainViewerElement.toggle();
    }

    resize() {
      if(this.objUserSessionsGrid)
        this.objUserSessionsGrid.resize();

    }

}

export {UserSessionsController as default};