﻿import { tradeideas } from "../Server/tradeideas"

class MainMenuController {

    constructor(application){
        this.application = application;
    }
         
    init(mainNav) {

        try {
            this.menuBarViewModel = kendo.observable({
                isVisible: true,
                isConnected: false,
                isNotConnected:true,
                loadSkin: function(events) {
                    var skinName = events.target.parentElement.getAttribute('data-skin');
                    this.application.skinsController.changeSkin(skinName);
                }.bind(this),
                doLogout: function (events) {
                    location.reload();
                },
                doClear: function (events) {
                    $("#mainApplication").children().hide();
                },
                showToken: function (events) {
                   window.prompt("Copy to clipboard: Ctrl+C, Enter", tradeideas.getMarketDataConnectionUrl());
                },
                onNavigate: function (events) {

                    $("#mainApplication").children().hide();
                    var viewId = events.target.parentElement.getAttribute('data-view');
                    $(viewId).show();
                    if(viewId==="#microProxyView" && !this.application.microProxyController.isInitilized){
                        this.application.microProxyController.init();
                    }
                    
                    this.application.resize();

                }.bind(this),
                connectMarketData: function()
                {
                    
                    this.menuBarViewModel.set("isNotConnected",false)
                    
                    tradeideas.initializeStreamingMarketData(
                        function(){
                            this.menuBarViewModel.set("isConnected",true);
                            this.menuBarViewModel.set("isNotConnected",false);
                            $("#mainApplication").children().hide();
                            $("#marketDataView").show();
                            this.application.resize();
                        }.bind(this),
                        function(){
                            this.menuBarViewModel.set("isConnected",false);
                            this.menuBarViewModel.set("isNotConnected",true);
                        }.bind(this)
                    )

                    tradeideas.initializeThrottledStreamingMarketData(
                        function(){
                        
                        }.bind(this),
                        function(){
                        }.bind(this))
                    
                        //$("#mainApplication").children().hide();
                       // $("#marketDataView").show();
                
                    }.bind(this)
            });

            kendo.bind(mainNav, this.menuBarViewModel);

        } catch (error) {
            tradeideas.errorHandler(error);
        }
    }
}


export {MainMenuController as default};
