import { tradeideas } from "../Server/tradeideas"

class MicroProxyController {

    constructor(){
        this.isInitilized = false;
        this.pingMessage = "command=ping&response=1";
    }

    init() {
        
        this.isInitilized = true;

        try {
            this.splitter = $("#vertical").kendoSplitter({
                orientation: "vertical",
                panes: [
                    { collapsible: false, resizable: true},
                    { collapsible: false, resizable: true},
                ]
            }).data("kendoSplitter");

            this.splitter.size(".k-pane:first", "200px")
        

            this.contentViewModel = kendo.observable({
                requestValue: '',
                messageLogValue: '',
                sendRequest: function(){

                           
                    tradeideas.microProxySendMessage(this.contentViewModel.requestValue);

                    this.contentViewModel.set('messageLogValue', "Request: " + (new Date()).toUTCString() + "\n" +
                                                                 "--------------------------------------------------------------------" + "\n" +
                                                                 this.contentViewModel.requestValue + '\n\n' +
                                                                 this.contentViewModel.messageLogValue) + "\n";

                }.bind(this),
                clearSendRequest:function(){
                    this.contentViewModel.set('requestValue', '');
                }.bind(this),
                clearMessage: function(){
                    this.contentViewModel.set('messageLogValue', '');
                }.bind(this)
            });
            kendo.bind($("#microProxyContentContainer"), this.contentViewModel);

            $(microProxyToolbar).kendoToolBar({
                items:[
                    {
                        template:$('#microProxyToolbarTemplate').html()
                    }
                ]
            })
 
            this.toolbarViewModel = kendo.observable({
                isConnected: false,
                connectMicroProxy: function(){
 
                    tradeideas.startWebSocketTunnelConnection(
                   
                        //Message Callback
                        function(message){

                            this.contentViewModel.set('messageLogValue', "Response: " + (new Date()).toUTCString() + "\n" +
                                                                        "--------------------------------------------------------------------" + "\n" +
                                                                        message + '\n\n' +
                                                                        this.contentViewModel.messageLogValue + "\n");

                        // $('#object').scrollTop($('#object')[0].scrollHeight);
                        
                        }.bind(this),                        

                        //Connection Success Callback
                        function(){

                            //Hide Connect Button 
                            //Show Disconnect Button
                            this.toolbarViewModel.set('isConnected', true);

                            this.pingTimer = setInterval(() => {
                                tradeideas.microProxySendMessage(this.pingMessage);
                                this.contentViewModel.set('messageLogValue', "Request: " + (new Date()).toUTCString() + "\n" +
                                                          "--------------------------------------------------------------------" + "\n" +
                                                          this.pingMessage + '\n\n' +
                                                          this.contentViewModel.messageLogValue) + "\n";
                            }, 60000);

                            tradeideas.microProxySendMessage(this.pingMessage);
                            this.contentViewModel.set('messageLogValue', "Request: " + (new Date()).toUTCString() + "\n" +
                                                    "--------------------------------------------------------------------" + "\n" +
                                                    this.pingMessage + '\n\n' +
                                                    this.contentViewModel.messageLogValue) + "\n";

                        }.bind(this),

                        //Connection Error Callback                            
                        function(){
                            this.toolbarViewModel.set('isConnected', false);
                            // this.microProxyToolbarObj.refresh();
                            alert("Error Connecting to Micro Proxy");
                        }.bind(this)
                    );
                }.bind(this),
                disconnectMicroProxy: function(){
                    
                    this.contentViewModel.set('requestValue', '');
                    this.contentViewModel.set('messageLogValue', '');
                    tradeideas.stopWebSocketTunnelConnection();
                    this.toolbarViewModel.set('isConnected', false);
                    // this.microProxyToolbarObj.refresh();
                   alert("Disconnect from the Micro Proxy");
                }.bind(this)

                // ,
                // connectMarketData: function(){

                //     TIJSAPI.MarketDataClient.Connect(this.connectionCallback.bind(this), this.tradeQuoteCallback.bind(this)) 
                //     .then((data) => {
                //         console.log(data);
                //     })
                //     .catch((error) => {
                //         console.log(error)        
                //     });

                // }.bind(this),
            });

            kendo.bind($("#microProxyToolbar"), this.toolbarViewModel);

            this.microProxyToolbarObj = $(microProxyToolbar).data("kendoToolBar");

            this.resize();
    
        }
        catch (error) {
            tradeideas.errorHandler(error);
        }

        $(window).resize(function () {

            this.resize();

        }.bind(this));

    }

    resize() {
      if(this.microProxyToolbarObj)
        this.microProxyToolbarObj.resize(true);
      if(this.splitter)
        this.splitter.resize(true);
     
    }

    tradeQuoteCallback( message){

    }

    connectionCallback( message){

    }
   
}

export {MicroProxyController as default};
