﻿import { tradeideas } from "../Server/tradeideas"
import { settingsController } from "./Settings"

class SkinsController {

    constructor(){

        this.skinPath = './kendo/kendo.{0}.min.css';
        this.curSkinName = '';
    }
    
    init(headView) {

        try {
            var settings = settingsController.getSettings();
            let skinName = ''
            if (settings.lastTheme)
                skinName = settings.lastTheme;
            else
                skinName = "moonlight";

            this.skinViewModel = kendo.observable({ url: this.skinPath.format(skinName)});
            kendo.bind(headView, this.skinViewModel);
            this.changeSkin(skinName);

        } catch (error) {
            tradeideas.errorHandler(error);
        }
    }

    changeSkin(skinName) {

        try {
            this.curSkinName = skinName
            this.skinViewModel.set('url', this.skinPath.format(skinName));
            var settings = settingsController.getSettings();
            settings.lastTheme = skinName;
            if(this.onSkinChanged!=null){
                this.onSkinChanged(skinName)
            }
            // endpointLogController.switchTheme(skinName);
            settingsController.saveSettings(settings);

        } catch (error) {
            tradeideas.errorHandler(error);
        }
    }

    addSkinChangeEvent(event){
        this.onSkinChanged = event;
    }

}

export {SkinsController as default};
