﻿import { tradeideas } from "../Server/tradeideas"

class StatusBarController {


    constructor(){

    }

    init(statusBarControl) {

        try {
        
            this.statusbarViewModel = kendo.observable({
                loggedInUser: "",
                TradeIdeasVersion: "8.3",
                isVisible: true
            });

            kendo.bind(statusBarControl, this.statusbarViewModel);

            this.statusbarViewModel.set('screenResolution', this.getScreenResolution());

            $(window).resize(function () {
                this.statusbarViewModel.set('screenResolution', this.getScreenResolution());
               
            }.bind(this));


        } catch (error) {
            tradeideas.errorHandler(error);
        }
    }

    getScreenResolution() {

        return ($(window).width() + "x" + $(window).height());
    }


    //function _heartBeat() {

    //    __serverTime = moment();

    //    var logonDuration = moment.duration(__serverTime.diff(__logonTime)).format("d[d] h:mm:ss", { trim: false });

    //    if (_sessionCallback)
    //        _sessionCallback(__serverTime, logonDuration);

    //}


    updateUserName(userName) {

        this.statusbarViewModel.set('loggedInUser', userName);
    }

    updateStatusBar(serverTime, logonDuration) {

       // this.statusbarViewModel.set('serverTime', serverTime.format("MM/DD/YYYY HH:mm:ss"));
       // this.statusbarViewModel.set('connectedDuration', logonDuration);
    }
}

export {StatusBarController as default};
