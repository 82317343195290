﻿import { tradeideas } from "../../Server/tradeideas"
import Config from "../../Configs";
class TradesAndQuotes {

    constructor(){
        this.latestData = {}
    }
    init() {


        try {


            

            this.dsTradesAndQuotes = new kendo.data.DataSource(
                {
                    schema: {
                        model: {
                            id: "Symbol",
                            fields: {
                                Symbol: { editable: false },
                                TradeTime: { nullable: true, type: "date" },
                                TradeSize: { nullable: true, type: "number" },
                                TradePrice: { nullable: true, type: "number" },
                                QuoteTime: { nullable: true, type: "date" },
                                QuoteBidSize: { nullable: true, type: "number" },
                                QuoteBid: { nullable: true, type: "number" },
                                QuoteBidExchange:  { editable: true },
                                QuoteAskSize: { nullable: true, type: "number" },
                                QuoteAsk: { nullable: true, type: "number" },
                                QuoteAskExchange:  { editable: true },
                                MessageCount: { nullable: true, type: "number" },
                                Volume: { nullable: true, type: "number" },
                                Exchange: { editable: true },
                            }
                        }
                    },
                    batch: false,
                    change: function (e) {
                    }
                });

            $("#tradeAndQuoteGrid").on("keydown", function (event) {

                if (event.key == "Delete") {

                    //dataItem = this.objQuoteGrid.dataItem(this.objQuoteGrid.select());

                    tradeideas.unsubscribeFromTradeAndQuote(this.lastDataItem.Symbol);

                    setTimeout(function (e) {
                        this.objTradeAndQuoteGrid.dataSource.remove(this.lastDataItem);
                    }.bind(this), 5000);

                }
            }.bind(this));

            $(tradeAndQuoteGrid).kendoGrid({
                scrollable: "true",
                navigatable: "true",
                dataSource: this.dsTradesAndQuotes,
                autobind: false,
                selectable: "row",
                sortable: true,
                //filterable: true,
                allowCopy: true,
                resizable: true,
                toolbar: [{ 'template': $('#tradesAndQuotesToolbarTemplate').html() }],
                height: "100%",
                 remove: function (e) {
                     this.objTradeAndQuoteGrid.select("tr[data-uid='" + e.model.uid + "']");
                 }.bind(this),
                 beforeEdit: function (e) {
                     this.objTradeAndQuoteGrid.select("tr[data-uid='" + e.model.uid + "']");
                 }.bind(this),
                 change: function (events) {
                     try {
                        this.lastDataItem = events.sender.dataItem(events.sender.select());

                     } catch (e) {
                     }
                 }.bind(this),
                columns: [
                    {
                        field: "Logo",
                        template: "<div class='symbol-logo' data-imgSrcSymbol='#:data.Symbol#'></div>",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                        width: "60px"
                    },
                    {
                        field: "Exchange",
                        title: "Exchange",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" },
                    },
                    {
                        field: "Symbol",
                        title: "Symbol",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "TradeTime",
                        title: "Trade Time",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:HH:mm:ss.fff}"
                    },
                    {
                        field: "TradeSize",
                        title: "Trade Size",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N1}"
                    },
                    {
                        field: "TradePrice",
                        title: "Trade Price",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N3}"
                    },
                    {
                        field: "Volume",
                        title: "Volume",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"
                    },
                    {
                        field: "QuoteTime",
                        title: "Quote Time",
                        attributes: { class: "grid-data-right" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:HH:mm:ss.fff}"
                    },
                    {
                        field: "QuoteBidSize",
                        title: "Bid Size",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N1}"
                    },
                    {
                        field: "QuoteBid",
                        title: "Bid",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N3}"
                    },
                    {
                        field: "QuoteBidExchange",
                        title: "Bid Exchange",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                    },
                    {
                        field: "QuoteAskSize",
                        title: "Ask Size",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N1}"
                    },
                    {
                        field: "QuoteAsk",
                        title: "Ask",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N3}"
                    },
                    {
                        field: "QuoteAskExchange",
                        title: "Ask Exchange",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                    },
                    {
                        field: "MessageCount",
                        title: "Message Count",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:N0}"
                    }
                ]
            });


            this.viewModel = kendo.observable({

                newSymbol: "",
                onKeyPress: function (event) {
                    if (event.keyCode == 13) {
                        var symbol = this.symbolInput = $("#addSymbolTradeAndQuote")[0].value;
                        tradeideas.subscribeToTradeAndQuote(symbol);
                        this.viewModel.set('newSymbol', null);
                        event.preventDefault();
                    }
                }.bind(this),
                onClick: function (event) {

                    var symbol = this.viewModel.get('newSymbol');
                    this.viewModel.set('newSymbol', null);
                    tradeideas.subscribeToTradeAndQuote(symbol);
                }.bind(this)
            });


            kendo.bind($("#tradeAndQuoteView"), this.viewModel);

            $(".k-grid-symbol", "#tradeGrid").bind("click", function (ev) {

                tradeideas.subscribeToTradeAndQuote("FB");
                tradeideas.subscribeToTradeAndQuote("GOOG");
                tradeideas.subscribeToTradeAndQuote("AAPL");
                tradeideas.subscribeToTradeAndQuote("F");
                tradeideas.subscribeToTradeAndQuote("VZ");


            });

            //$("#tradeGrid .k-grid-content").css({
            //    "overflow-y": "hidden"
            //});

            this.objTradeAndQuoteGrid = $(tradeAndQuoteGrid).data("kendoGrid");

            //objTradeGrid.table.on("keydown", function (e) {
            //    if (e.keyCode == 46) {
            //        objTradeGrid.removeRow(grid.select());
            //    }
            //});

            this.objTradeAndQuoteGrid.wrapper.toggleClass("no-scrollbar");

            this.resize();

            tradeideas.setTradesAndQuotesCallbacks(this.tradesAndQuotesCallback.bind(this));
            //requestAnimationFrame(this.addTradesAndQuotesData.bind(this))


        } catch (error) {

            tradeideas.errorHandler(error);
        }

        $(window).resize(function () {

            this.resize();

        }.bind(this));

    }

    formatPrice(priceElement, Decimals, data) {

        var direction = Math.floor(Math.random() * 3) + 1;

        if (direction === 1) {
            return "<span class='positiveColor'>" + kendo.toString(priceElement, "N" + Decimals) + "</span>";
        } else if (direction === 2)
            return "<span class='positiveColor'>" + kendo.toString(priceElement, "N" + Decimals) + "</span>";
        else {
            return "<span class='negativeColor'>" + kendo.toString(priceElement, "N" + Decimals) + "</span>";
        }
    }

    toggle() {

        mainViewerElement.toggle();
    }

    resize() {

    if(this.objTradeAndQuoteGrid)
        this.objTradeAndQuoteGrid.resize();

    }

   

    tradesAndQuotesCallback(msg) {

        var item = this.objTradeAndQuoteGrid.dataSource.get(msg.Data[0]);
        if (item) {
            item.MessageCount++;
            item.set('TradeTime', new Date(msg.Data[1]));
            item.set('TradeSize', msg.Data[2]);
            item.set('TradePrice', msg.Data[3]);
            item.set('QuoteTime', new Date(msg.Data[4]));
            item.set('QuoteBidSize', msg.Data[5]);
            item.set('QuoteBid', msg.Data[6]);
            item.set('QuoteAskSize', msg.Data[7]);
            item.set('QuoteAsk', msg.Data[8]);
            item.set('MessageCount', item.MessageCount);
            item.set('Volume', msg.Data[9]);
            item.set('Exchange', msg.Data[10]);
            //item.set('QuoteBidExchange', msg.Data[11]);
            //item.set('QuoteAskExchange', msg.Data[12]);

        }
        else {
            fetch(`${Config.tiCompanyLogosPath}/${msg.Data[0].replace('~d','')}.png`)
                .then(response => response.blob())
                .then(function(myBlob) {
                    var objectURL = URL.createObjectURL(myBlob);
                    let cssTag = document.createElement('style');
                    cssTag.innerHTML = `[data-imgsrcsymbol="${msg.Data[0]}"]{background-image:url('${objectURL}')}`
                    document.body.appendChild(cssTag)
                });
            var trade = {
                "Symbol": msg.Data[0],
                "TradeTime": new Date(msg.Data[1]),
                "TradeSize": msg.Data[2],
                "TradePrice": msg.Data[3],
                "QuoteTime": new Date(msg.Data[4]),
                "QuoteBidSize": msg.Data[5],
                "QuoteBid": msg.Data[6],
                "QuoteAskSize": msg.Data[7],
                "QuoteAsk": msg.Data[8],
                "MessageCount": 1,
                "Volume" : msg.Data[9],
                "Exchange": msg.Data[10],
                //"QuoteBidExchange": msg.Data[11],
                //"QuoteAskExchange": msg.Data[12]

            };
            this.objTradeAndQuoteGrid.dataSource.pushUpdate(trade);
        }
    }

}

export {TradesAndQuotes as default};