﻿import Config from "../Configs";

//Trade Ideas - Broker Services API Wrapper 
class Tradeideas {

    constructor(){
        this.targetGroup="DotNetServices";
        this.sessionToken;
        this.microProxyConnection;
        this.throttledMarketDataConnection;
        this.marketDataConnection;
        this.userName;
        this.tradesCallbacks = [];
        this.quotesCallbacks = [];
        this.tradesAndQuotesCallbacks = [];
        this.messageId = 0;
    }
  
    setSessionToken(token) {

        this.sessionToken = token;
    }

    getUserName() {

        return this.userName;
    }


    getSessionToken() {

        return this.sessionToken;
    }
    
    getMarketDataConnectionUrl() {

        return Config.tiBrokerServicesDomainWS + 
        "/MarketData/throttled-marketdata?applicationId=1&apiKey=" + Config.apiKey + "&userName=" + 
        this.userName + "&throttleMS=500&access_token=" + this.sessionToken;
    }

    sendEndpointMessage(verb, body, path, cb) {

        this.sendMessage(tiBrokerServicesDomain, verb, body, path + "?api_key=" + this.sessionToken, cb);

        return;
    }
    
    sendMessage(server,verb,body,path, cb) {

        let url = server + path;
        $.ajaxSetup({ cache: false,
            beforeSend: function(xhr){
                if(this.sessionToken ){
                    xhr.setRequestHeader('Authorization', 'Bearer ' + this.sessionToken)
                }
                if(this.targetGroup ){
                    xhr.setRequestHeader('TI-Target-Group', this.targetGroup)
                }
            }.bind(this)
        });
        $.ajax({
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            type: verb,
            dataType: "json",
            url: url,
            data: JSON.stringify(body),
            success: function (data, textStatus, xhr) {
                cb(data,xhr);
            },
            error: function (data,textStatus,xhr) {
                cb(data,xhr);
            }
        });

        return; 
    }

    logon(userName, password, cb) {
        
        let logonRequest = {applicationId:2, applicationVersion: "2.1",apiKey: "q8DPrv1kjstfVqdXwZmjbKE1wxu2ybsG", username: userName, password: password};      
          this.sendMessage(Config.tiBrokerServicesDomainREST, "POST", logonRequest, "/Administration/logon",
            function (logonResponse, xhr) {

                if (logonResponse && xhr.status == 200) {

                    this.setSessionToken(logonResponse.Data.Token);
                    this.userName = logonResponse.Data.UserName;
                    // this.initializeStreamingMarketData();
                    
                    cb("OK", logonResponse);
                }
                else {
                    cb("ERROR",logonResponse);
                }
            }.bind(this)
        );
    }

    getUserSessions( username, startDate,endDate,cb){

        this.sendMessage(Config.tiBrokerServicesDomainREST, "GET", null, "/Administration/user-sessions?userName=" + username + "&startDate=" + startDate + "&endDate=" + endDate,
 
        function (response, xhr) {

                if (response && xhr.status == 200) {
                    cb(response);
                }
                else {
                    cb(response);
                }
            }
        );

    }

    getMarketDataSessions( username, startDate,endDate,cb){

        this.sendMessage(Config.tiBrokerServicesDomainREST, "GET", null, "/Administration/marketdata-sessions?userName=" + username + "&startDate=" + startDate + "&endDate=" + endDate,
 
        function (response, xhr) {

                if (response && xhr.status == 200) {
                    cb(response);
                }
                else {
                    cb(response);
                }
            }
        );

    }

    getMarketDataStatisticsReport(startDate, endDate, user, symbol, cb){

        this.sendMessage(Config.tiBrokerServicesDomainREST, "GET", null, "/Administration/marketdata-statistics-report?startDate=" + startDate + "&endDate=" + endDate,
 
        function (response, xhr) {

                if (response && xhr.status == 200) {
                    cb(response);
                }
                else {
                    cb(response);
                }
            }
        );

    }

    getMarketDataUserSummaryReport(startDate, endDate, user, symbol, cb){

        this.sendMessage(Config.tiBrokerServicesDomainREST, "GET", null, "/Administration/marketdata-user-summary-report?startDate=" + startDate + "&endDate=" + endDate,
 
        function (response, xhr) {

                if (response && xhr.status == 200) {
                    cb(response);
                }
                else {
                    cb(response);
                }
            }
        );

    }

    getMarketDataSymbolSummaryReport(startDate, endDate, user, symbol, cb){

        this.sendMessage(Config.tiBrokerServicesDomainREST, "GET", null, "/Administration/marketdata-symbol-summary-report?startDate=" + startDate + "&endDate=" + endDate,
 
        function (response, xhr) {

                if (response && xhr.status == 200) {
                    cb(response);
                }
                else {
                    cb(response);
                }
            }
        );

    }

    getMarketDataSubscriptions( sessionId,cb){

        this.sendMessage(Config.tiBrokerServicesDomainREST, "GET", null, "/Administration/marketdata-subscriptions?sessionId=" + sessionId,
 
        function (response, xhr) {

                if (response && xhr.status == 200) {
                    cb(response);
                }
                else {
                    cb(response);
                }
            }
        );

    }

    getOrders(user, orderStatus, startDate, endDate, cb){

        this.sendMessage(Config.tiBrokerServicesDomainREST, "GET", null, "/Alpaca/Live/orders?user=" + user + "&orderStatus=" + orderStatus + "&startDate=" + startDate + "&endDate=" + endDate,
 
        function (response, xhr) {

                if (response && xhr.status == 200) {
                    cb(response);
                }
                else {
                    cb(response);
                }
            }
        );

    }
  
    getbrokerUserSessionsHistory(user, startDate, endDate, cb){
            
        this.sendMessage(Config.tiBrokerServicesDomainREST, "GET", null, "/Alpaca/user-sessions-history?user=" + user + "&startDate=" + startDate + "&endDate=" + endDate,
        function (response, xhr) {

                if (response && xhr.status == 200) {
                    cb(response);
                }
                else {
                    cb(response);
                }
            }
        );
    }


    getbrokerUserSessionLog(brokerUserSessionId, cb){
            
        this.sendMessage(Config.tiBrokerServicesDomainREST, "GET", null, "/Alpaca/user-session-log?brokerUserSessionId=" + brokerUserSessionId,
        function (response, xhr) {

                if (response && xhr.status == 200) {
                    cb(response);
                }
                else {
                    cb(response);
                }
            }
        );
    }

    getbrokerUserSessions(server, cb){
        
        this.sendMessage(Config.tiBrokerServicesDomainREST, "GET", null, "/Alpaca/user-sessions?TI-Target-Instance=" +  server + "&TI-Target-Group=DotNetServices",
        function (response, xhr) {

                if (response && xhr.status == 200) {
                    cb(response);
                }
                else {
                    cb(response);
                }
            }
        );
    }

    getWebSocketGatewayActiveConnections(server, cb){

        this.sendMessage(Config.tiWebSocketGatewayDomainREST, "GET", null, "/WebSocketTunnel/connections?TI-Target-Instance=" + server,
 
        function (response, xhr) {

                if (response && xhr.status == 200) {
                    cb(response);
                }
                else {
                    cb(response);
                }
            }
        );

    }

    //Micro Proxy Connection
    startWebSocketTunnelConnection(messageCb,successCb,errorCb) {

        this.messageId = 0;

        if (!this.microProxyConnection) {
            this.microProxyConnection = new WebSocket(Config.tiWebSocketGatewayDomainWS + 
                                                    "/WebSocketTunnel/connect?access_token=" + this.sessionToken +
                                                    "&applicationId=2" +
                                                    "&userName=" + this.userName);
            this.microProxyConnection.binaryType = "arraybuffer"; 
        } 

        this.microProxyConnection.onmessage = function (payload) {

            
            let decoder = new TextDecoder();

            const sm = decoder.decode(payload.data);
           
            messageCb(sm);
            
        }.bind(this);

        this.microProxyConnection.onopen = function (data) {
            successCb()
        };

        this.microProxyConnection.onerror = function (data) {
            errorCb()
        };

        this.microProxyConnection.onclose = function (data) {
            this.messageId = 0;
            this.microProxyConnection = null;
            errorCb();
        }.bind(this);


    }

    stopWebSocketTunnelConnection() {


        this.messageId = 0;

        this.microProxyConnection.close();

        this.microProxyConnection = null;

    }

    microProxySendMessage(message) {

        this.messageId++;
        message += "&message_id=" + this.messageId + "\r\n";
        this.microProxyConnection.send(message);

        return message;
    }

    //Throttled Streaming  Market Data
    initializeThrottledStreamingMarketData(successCb,errorCb) {

        if (!this.throttledMarketDataConnection) {
            this.throttledMarketDataConnection = new WebSocket(Config.tiBrokerServicesDomainWS + 
                                                      "/MarketData/throttled-marketdata?applicationId=1&apiKey=" + Config.apiKey + "&userName=" + 
                                                      this.userName + "&throttleMS=500&access_token=" + this.sessionToken);
        } 

        this.throttledMarketDataConnection.onmessage = function (payload) {

            let message = JSON.parse(payload.data);
            switch (message.MessageType) {

                case 90:

                    break;

                case 53:

                    try {
                        for (let i = 0; i < this.tradesAndQuotesCallbacks.length; i++) {
                            this.tradesAndQuotesCallbacks[i](message);
                        }
                    }
                    catch (exception) {
                        //;
                    }
                    break;
                case 75:
                    break;
            }
            
        }.bind(this);

        this.throttledMarketDataConnection.onerror = function (data) {
            errorCb()
        };


        this.throttledMarketDataConnection.onopen = function (data) {
            successCb()
        };
        
    }

    //Regular Streaming  Market Data
    initializeStreamingMarketData(successCb,errorCb) {

        if (!this.marketDataConnection) {
            this.marketDataConnection = new WebSocket(Config.tiBrokerServicesDomainWS + 
                                                      "/MarketData/marketdata?applicationId=1&apiKey=" + Config.apiKey + "&userName=" + 
                                                      this.userName + "&throttleMS=500");
        } 

        this.marketDataConnection.onmessage = function (payload) {

            let message = JSON.parse(payload.data);
            switch (message.MessageType) {

                case 90:

                    break;
                case 51:
                    try {
                        for (let i = 0; i < this.tradesCallbacks.length; i++) {
                            this.tradesCallbacks[i](message);
                        }
                    }
                    catch (exception) {
                        var e = 1;
                    }
                    break;
                case 52:

                    try {
                        for (let i = 0; i < this.quotesCallbacks.length; i++) {
                            this.quotesCallbacks[i](message);
                        }
                    }
                    catch(exception)
                    {
                        var e = 1;
                    }
                    break;

                    case 75:
                    break;
            }
            
        }.bind(this);

        this.marketDataConnection.onerror = function (data) {
            errorCb()
        };


        this.marketDataConnection.onopen = function (data) {
            successCb()
        };
        
    }

    subscribeToTrade(symbol) {
        this.marketDataConnection.send(JSON.stringify({ "MessageType": 1, "DataTypes": [1], "Symbols": [symbol] }));
    }
    unsubscribeFromTrade(symbol) {
        this.marketDataConnection.send(JSON.stringify({ "MessageType": 2, "DataTypes" : [1], "Symbols": [symbol] }));
    }
    
    subscribeToQuote(symbol) {
        this.marketDataConnection.send(JSON.stringify({ "MessageType": 1, "DataTypes": [2], "Symbols": [symbol] }));
    }

    unsubscribeFromQuote(symbol) {
        this.marketDataConnection.send(JSON.stringify({ "MessageType": 2, "DataTypes": [2], "Symbols": [symbol] }));
    }

    subscribeToTradeAndQuote(symbol) {
        this.throttledMarketDataConnection.send(JSON.stringify({ "MessageType": 1, "DataTypes": [3], "Symbols": [symbol] }));
    }
    
    unsubscribeFromTradeAndQuote(symbol) {
        this.throttledMarketDataConnection.send(JSON.stringify({ "MessageType": 2, "DataTypes": [3], "Symbols": [symbol] }));
    }

    setTradesCallbacks(callback) {

        this.tradesCallbacks.push( callback);

    }

    setQuotesCallbacks(callback) {

        this.quotesCallbacks.push(callback);

    }

    setTradesAndQuotesCallbacks(callback) {

        this.tradesAndQuotesCallbacks.push(callback);

    }

    setApiPaths(domain) {


        this.tiBrokerServicesDomain = domain;

    }
    
    errorHandler(error) {
        console.log(error)
    }

}

export const tradeideas = new Tradeideas();
