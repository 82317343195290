import { tradeideas } from "../../Server/tradeideas"

class BrokerUserSessionsHistoryController {
    constructor() {

    }

    init() {

        try {

            this.dsBrokerUserSessionsHistory = new kendo.data.DataSource(
                {
                    schema: {
                        model: {
                            id: "id",
                            fields: {
                                ApplicationName: {type: "string" }, 
                                ApplicationVersion: {type: "string" }, 
                                Environment: {type: "string" }, 
                                TargetInstance: { type: "string" },
                                Connected: { type: "bool" },
                                BrokerUserId: { type: "number" },
                                UserName: { type: "string" },
                                UserSessionId: { type: "number" },
                                NextOrderId: { type: "number" },
                                LocalOrders: { type: "number" },
                                BrokerUserSessionId: { type: "number" },
                                StatusType: { type: "string" },
                                BrokerUserSessionCreatedWhen: { type: "date" },
                                BrokerUserSessionUpdatedWhen: { type: "date" },
                            }
                        }
                    },
                    batch: false
                });

                this.dsBrokerUserSessionLogTemplate = 
                {
                    schema: {
                        model: {
                            id: "BrokerUserSessionLogId",
                            fields: {
                                BrokerUserSessionId: {editable: false,type: "number"},
                                BrokerUserSessionLogId: {editable: false,type: "number"},
                                ApplicationName: {type: "string" }, 
                                ApplicationVersion: {type: "string" }, 
                                StatusTypeId: { type: "number" },
                                StatusType: { type: "string" },
                                CreatedWhen: { nullable: true, type: "date" }
                            }
                        }
                    },
                    batch: false
                };


            $(brokerUserSessionsHistoryGrid).kendoGrid({
                scrollable: "true",
                navigatable: "true",
                dataSource: this.dsBrokerUserSessionsHistory,
                autobind: false,
                selectable: "row",
                sortable: true,
                filterable: true,
                allowCopy: true,
                resizable: true,
                toolbar: [{ 'template': $('#brokerUserSessionHistoryToolbarTemplate').html() }],
                editable: "inline",
                height: "100%",
                detailInit: this.brokerUserSessionLogInit.bind(this),
                columns: [
                    {
                        field: "ApplicationName",
                        title: "Application",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "ApplicationVersion",
                        title: "Version",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "Environment",
                        title: "Environment",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "TargetInstance",
                        title: "Instance",
                        attributes: { class: "grid-data-left" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "Connected",
                        title: "Connected",
                        attributes: { class: "grid-data-center" },
                        //template: '<input type="checkbox" #= Connected ? \'checked="checked"\' : "" # class="chkbx k-checkbox k-checkbox-md k-rounded-md" />',
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "BrokerUserId",
                        title: "User Id",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                    },
                    {
                        field: "UserName",
                        title: "User Name",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },

                    },
                    {
                        field: "UserSessionId",
                        title: "User Ses.Id",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "NextOrderId",
                        title: "Order Id",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                    },
                    {
                        field: "LocalOrders",
                        title: "Local Orders",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },

                    },
                    {
                        field: "BrokerUserSessionId",
                        title: "Broker Ses.Id",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" }
                    },
                    {
                        field: "StatusType",
                        title: "Status",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                    },
                    {
                        field: "BrokerUserSessionCreatedWhen",
                        title: "Created",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:HH:mm:ss.fff}",
                        width: "150px"
                    },
                    {
                        field: "BrokerUserSessionUpdatedWhen",
                        title: "Updated",
                        attributes: { class: "grid-data-center" },
                        headerAttributes: { class: "grid-header-center" },
                        format: "{0:HH:mm:ss.fff}",
                        width: "150px"
                    }
                ]
            });

            var currentDate = new Date();
            this.viewModel = kendo.observable({

                brokerUserSessionsHistoryUserName: "",
                brokerUserSessionsHistoryStatus: "",
                brokerUserSessionsHistoryFromVisible: true,
                brokerUserSessionsHistoryFromValue: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7),
                brokerUserSessionsHistoryToVisible: true,
                brokerUserSessionsHistoryToValue: currentDate,

                onClick: function (event) {

                    var startDate = this.viewModel.get('brokerUserSessionsHistoryFromValue');
                    var endDate = this.viewModel.get('brokerUserSessionsHistoryToValue');
                    var user = this.viewModel.get('brokerUserSessionsHistoryUserName');
                    kendo.ui.progress($("#mainApplication"), true);

                    this.objbrokerUserSessionsHistoryGrid.setDataSource([]);
                    this.dsBrokerUserSessionsHistory.data([]);

                    tradeideas.getbrokerUserSessionsHistory(user, startDate.toISOString(), endDate.toISOString(), function (result) {

                        if (result.Data) {

                            for (let i = 0; i < result.Data.length; i++) {

                                var record = result.Data[i];

                                if (record.LastConnectedWhen)
                                    record.LastConnectedWhen = new Date(record.LastConnectedWhen);

                                if (record.BrokerUserSessionCreatedWhen)
                                    record.BrokerUserSessionCreatedWhen = new Date(record.BrokerUserSessionCreatedWhen);

                                if (record.BrokerUserSessionUpdatedWhen)
                                    record.BrokerUserSessionUpdatedWhen = new Date(record.BrokerUserSessionUpdatedWhen);
                                this.dsBrokerUserSessionsHistory.add(record);
                            }
                        }

                        kendo.ui.progress($("#mainApplication"), false);
                        this.objbrokerUserSessionsHistoryGrid.setDataSource(this.dsBrokerUserSessionsHistory);

                    }.bind(this));

                    kendo.ui.progress($("#mainApplication"), false);

                }.bind(this)
            });
            kendo.bind($("#brokerUserSessionsHistoryView"), this.viewModel);

            this.objbrokerUserSessionsHistoryGrid = $(brokerUserSessionsHistoryGrid).data("kendoGrid");

            this.resize();

        } catch (error) {

            tradeideas.errorHandler(error);
        }

        $(window).resize(function () {

            this.resize();

        }.bind(this));

    }

    brokerUserSessionLogInit(e) {

        kendo.ui.progress($("#mainApplication"), true);

        tradeideas.getbrokerUserSessionLog(e.data.BrokerUserSessionId, function (result) {

            if (result.Data  && result.Data.length > 0) {


                for (let i = 0; i < result.Data.length; i++) {
                    var record = result.Data[i];
                    record.CreatedWhen = new Date(record.CreatedWhen);
                }

                this.dsBrokerUserSessionLogTemplate.data =result.Data ;

                var grid  = $("<div/>").appendTo(e.detailCell).kendoGrid({
                    scrollable: "true",
                    navigatable: "true",
                    dataSource: new kendo.data.DataSource(this.dsBrokerUserSessionLogTemplate),
                    filterable: true,
                    autobind: false,
                    selectable: "column",
                    sortable: true,
                    resizable: true,
                    allowCopy: true,
                    height: "250px",
                    columns: [
                        {
                            field: "BrokerUserSessionId",
                            title: "Broker User Sesion Id",
                            attributes: { class: "grid-data-center" },
                            headerAttributes: { class: "grid-header-center" },
                            format: "{0:N0}"
        
                        },
                        {
                            field: "BrokerUserSessionLogId",
                            title: "Broker User Sesion Log Id",
                            attributes: { class: "grid-data-center" },
                            headerAttributes: { class: "grid-header-center" },
                            format: "{0:N0}"
                        },
                        {
                            field: "TargetInstance",
                            title: "Target Instance",
                            attributes: { class: "grid-data-left" },
                            headerAttributes: { class: "grid-header-center" }
                        },
                        {
                            field: "StatusType",
                            title: "Status",
                            attributes: { class: "grid-data-left" },
                            headerAttributes: { class: "grid-header-center" }
                        },
                        {
                            field: "CreatedWhen",
                            title: "Created",
                            attributes: { class: "grid-data-left" },
                            headerAttributes: { class: "grid-header-center" },
                            format: "{0:yyyy-MM-dd HH:mm:ss.fff}",
                            width: "200px"
                        }
                    ]
                }).data("kendoGrid");

                
            }
            else {
              // alert("No Records Found");
               return; 
            }

            kendo.ui.progress($("#mainApplication"), false);

        }.bind(this));
             
    }    

    toggle() {

        mainViewerElement.toggle();
    }

    resize() {
        if (this.objbrokerUserSessionsHistoryGrid)
            this.objbrokerUserSessionsHistoryGrid.resize();

    }

}

export { BrokerUserSessionsHistoryController as default };