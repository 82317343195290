import { tradeideas } from "../Server/tradeideas"
import QuotesController from "../Controllers/MarketData/Quotes"
import TradesController from "../Controllers/MarketData/Trades"
import TradesAndQuotes from "../Controllers/MarketData/TradesAndQuotes"
class MarketDataController {
    constructor(){
        this.quotesController = new QuotesController();
        this.tradesController = new TradesController();
        this.tradesAndQuotesController = new TradesAndQuotes();
    }

    init() {
        this.quotesController.init();
        this.tradesController.init();
        this.tradesAndQuotesController.init();
        try {


     

            this.tabstrip = $("#marketDataTabStrip").kendoTabStrip().data("kendoTabStrip");
            this.tabstrip.select(2);


                                 
            this.resize();

        } catch (error) {

            tradeideas.errorHandler(error);
        }

        $(window).resize(function () {

            this.resize();

        }.bind(this));

    }


    resize() {
        if(this.quotesController){
            this.quotesController.resize();
        }
        if(this.tradesController){
            this.quotesController.resize();
        }
        if(this.tradesAndQuotesController){
            this.tradesAndQuotesController.resize();
        }

    }

}

export {MarketDataController as default};